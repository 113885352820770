@use '@angular/material' as mat;
.mat-step-header {
  font-weight: 400;

  .mat-step-label {
    @include mat.m2-typography-level($flex-app-typography, subtitle-1);
    font-size: 18px;
    font-weight: 400;
    opacity: 0.7;

    --mat-stepper-header-selected-state-label-text-size: 18px;
    --mat-stepper-header-selected-state-label-text-weight: 600;
  }

  .mat-step-label-selected {
    font-weight: 600;
    opacity: 1;
  }

  &:hover {
    background: none;
    cursor: default;
  }

  &[ng-reflect-active='false'] {
    .mat-ripple {
      display: none;
    }
  }

  &[ng-reflect-active='true']:hover {
    background: rgba(mat.m2-get-color-from-palette($md-digitallightblue, 100), 0.3);
    cursor: pointer;

    .mat-ripple {
      display: block;
    }
  }
}

.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused {
  background: none;
  font-weight: 500;
}

// TODO: generiek maken met card / dialog actions.
.stepper-buttons,
.button-bar {
  position: relative;
  margin-top: $gutter;

  > *:not(:last-child) {
    margin: 0;
    margin-right: $button-spacing;
  }
}

.inject-card-stepper-flow {
  .mat-mdc-card {
    box-shadow: none !important;

    .mat-mdc-card-actions,
    .mat-mdc-card-header {
      display: none;
    }
  }
}

.mat-vertical-content {
  padding: 24px !important;
}

.mat-step-icon .mat-icon {
  top: 0 !important;
  left: 0 !important;
  transform: 0 !important;
  text-align: center;
  vertical-align: middle;
  width: 17px !important;
}

// Horizontal stepper - As used in Customer portal. Need more specification if application will become more diverse.
.mat-stepper-horizontal {
  .mat-horizontal-stepper-header-container {
    padding-bottom: 24px;
    @include gradient(to bottom, rgba(white, 1) 80%, rgba(white, 0));
  }

  .mat-horizontal-stepper-header {
    border: solid 1px rgba(mat.m2-get-color-from-palette($md-digitallightblue, 200), 0.6);
    height: 30px;
    padding: 8px 15px;
    border-radius: $border-radius;
    font-weight: 500;

    .mat-step-label {
      opacity: 1;
    }

    .mat-step-text-label {
      margin-top: -2px;

      .chip {
        line-height: 18px;
      }
    }

    &[ng-reflect-selected='true'] {
      background: rgba(mat.m2-get-color-from-palette($md-digitallightblue, 100), 0.5);
    }

    .mat-step-icon-selected {
      background: rgba(mat.m2-get-color-from-palette($md-digitallightblue, 500), 1);
    }
  }
  .mat-stepper-horizontal-line {
    margin: 0 !important;
    border-top-color: rgba(mat.m2-get-color-from-palette($md-digitallightblue, 200), 0.6);
  }
  .mat-horizontal-content-container {
    padding: 0;
  }
}
