@use '@angular/material' as mat;

.mat-calendar-previous-button::after,
.mat-calendar-next-button::after {
  margin: 12px !important;
  width: 9px;
  height: 9px;
}

.mat-calendar-body-selected {
  background: mat.m2-get-color-from-palette($md-digitallightblue, 500) !important;
}

.mat-calendar-body-in-range::before {
  background: rgba(mat.m2-get-color-from-palette($md-digitallightblue, 500), 0.2) !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background: rgba(mat.m2-get-color-from-palette($md-digitallightblue, 500), 0.1) !important;
}

.mat-calendar-body-in-preview {
  color: rgba(mat.m2-get-color-from-palette($md-digitallightblue, 500), 0.8) !important;

  .mat-calendar-body-cell-preview {
    border-top: solid 1px !important;
    border-bottom: solid 1px !important;
  }
}

.mat-calendar-body-preview-end {
  .mat-calendar-body-cell-preview {
    border-right: solid 1px !important;
  }
}
