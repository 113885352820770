@use 'sass:math';

@use '@angular/material' as mat;
.info-box {
  border: solid 1px mat.m2-get-color-from-palette($md-digitallightblue, 300);
  border-radius: 6px;
  padding: $gutter * 0.5 $gutter * 0.5 $gutter * 0.5 $gutter;
  background: rgba(mat.m2-get-color-from-palette($md-digitallightblue, 50), 0.5);
  display: block;
  font-weight: 400;
  position: relative;
  margin-bottom: $gutter;

  > p {
    margin: 4px 0;

    + p {
      margin-top: 10px;
    }
  }

  em {
    font-weight: 700;
  }

  > .mat-icon {
    @extend .status-info;
    @include icon-size($ic-size-m);
    white-space: nowrap;
    margin-top: 1px;
    position: absolute;
    left: -6px;
    top: -6px;
    background: white;
    width: 16px !important;
    height: 20px !important;
    border-radius: 50%;
  }
}

*[matTooltip] {
  cursor: pointer;
}

.form-tooltip {
  color: mat.m2-get-color-from-palette($md-digitallightblue, 500);
  @include icon-size($ic-size-s);
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: rgba(97, 97, 97, 0.9);
  --mdc-plain-tooltip-supporting-text-color: white;
  --mdc-plain-tooltip-supporting-text-size: 12px;
}
