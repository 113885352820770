@use "sass:math";

@use 'sass:map';
@use '../core/styles/theme';

@mixin family($family) {
  font-family: #{map.get(theme.$ph, fontFamilies, $family)};
}

@mixin sizePx($size) {
  font-size: #{map.get(theme.$ph, fontSizes, $size)}px;
}

@mixin sizeRem($size) {
  $html-base: 13;
  font-size: math.div(map.get(theme.$ph, fontSizes, $size), $html-base) * 1rem;
}

@mixin lineHeight($size) {
  line-height: #{map.get(theme.$ph, lineHeights, $size)}em;
}
