@use '@angular/material' as mat;
@use '../src/lib/core/styles/media';
// FLEX LAYOUT GRIDS
// RESPONIVE BREAKPOINTS
$tablet-width: 768px;
$desktop-width: 1024px;
$widescreen: 1470px;
$widescreen-2k: 2030px;

// To quickly and easily make flex grids with configurable properties.
// Currently 2 - 4 columns are supported. But can be easily expanded. Only the responsive part needs to be changed for it. (column layout/calculation based on available width).
@mixin flex-grid(
  $number-of-columns: 4,
  $gutter-size: $gutter * 0.5,
  $height: 100%,
  $vspacing: 0,
  $hspacing: 0,
  $responsive-2k: $widescreen-2k,
  $responsive-1k: $widescreen,
  $responsive-desktop: $desktop-width,
  $responsive-tablet: $tablet-width
) {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$hspacing;
  margin-right: -$hspacing;

  @for $column-counter from 1 through $number-of-columns {
    > .col-#{$column-counter} {
      position: relative;
      box-sizing: border-box;
      padding: $vspacing $hspacing;
      width: calc(#{$column-counter} / #{$number-of-columns} * 100%);

      > .mat-mdc-card {
        height: 100%;
      }

      @media (max-width: #{$responsive-2k}) {
        @if ($number-of-columns > 3) {
          width: calc(#{$column-counter} / #{$number-of-columns} * 100%);
        } @else if ($number-of-columns == 3) {
          width: calc(#{$column-counter} / #{$number-of-columns - 1} * 100%);
        } @else if ($number-of-columns == 2) {
          width: calc(#{$column-counter} / #{$number-of-columns} * 100%);
        }
      }
      @media (max-width: #{$responsive-1k}) {
        @if ($number-of-columns > 2) {
          width: calc(#{$column-counter} / #{$number-of-columns - $number-of-columns * 0.5} * 100%);
        } @else if ($number-of-columns == 2) {
          width: calc(#{$column-counter} / #{$number-of-columns} * 100%);
        }
      }
      @media (max-width: #{$responsive-desktop}) {
        width: 100%;
      }
    }
  }
}

// Flex row. Responsive by default. Can be configured to alternative breakpoints ot not responsive at all.
@mixin flex-row($gutter-size: $gutter, $responsive: true, $responsive-to-column: $widescreen) {
  display: flex;
  width: 100%;

  &.has-separator {
    border-bottom: solid 1px mat.m2-get-color-from-palette($md-brightgrey, 500);
    margin-bottom: $gutter * 0.5;

    &:first-child {
      border-top: solid 1px mat.m2-get-color-from-palette($md-brightgrey, 500);
      padding-top: $gutter * 0.5;
    }
  }

  &.align-center {
    justify-content: center;
    text-align: center;
  }

  &.align-right {
    justify-content: flex-end;
    text-align: right;
  }

  @for $column-counter from 0 through 12 {
    > .col-#{$column-counter} {
      flex: $column-counter;

      &:not(:first-child) {
        margin-left: $gutter-size;
      }

      // Override default left margin in case it's not needed.
      &.no-space {
        margin-left: 0;
      }

      &.v-align-c {
        align-self: center;
      }

      &.align-right {
        text-align: right;
      }
    }

    // To make sizing a bit more compact and neat. Year select appeared relatively big.
    .year-select {
      max-width: 145px;

      .year {
        max-width: 85px;
      }
    }

    @if $responsive {
      @media (max-width: #{$responsive-to-column}) {
        flex-direction: column;

        &.mob-reverse {
          flex-direction: column-reverse !important;
        }

        > [class*='col-']:not(.mat-form-field) {
          margin-left: 0 !important;
          margin-bottom: $gutter-size;
          display: block;
          width: 100%;
          box-sizing: border-box;

          &.no-responsive-space {
            margin-bottom: 0;
          }

          // Positioner of total volumes in Customer availabilities screen when going responsive. Not ideal to put that in the mixin, but it needs to be injected in the responsive part.
          &.positioner {
            margin-top: 10px;

            > div {
              text-align: center;

              .label,
              .data {
                display: block;
                margin-left: 0;
              }
            }
          }

          // To reset the compact and neat sizing and make it go 100%.
          .year-select {
            max-width: none;

            .year {
              max-width: none;
            }
          }

          .mat-form-field {
            width: 100%;
          }

          .form-field-spacer {
            padding: 0 35px 0 24px;
            box-sizing: border-box;
          }
          .contracted-totals {
            text-align: center;
          }
        }
        > .mat-form-field[class*='col-'] {
          margin-left: 0 !important;
        }
      }
    }
  }

  &.label-with-data {
    > div[class^='col-']:first-child {
      white-space: nowrap;
      flex: 0 0 auto;
    }
  }
}

// To rotate stuff
@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}

// To gradient stuff
@mixin gradient($direction, $primary-color, $secondary-color) {
  background: -webkit-linear-gradient($direction, $primary-color, $secondary-color);
  background: -moz-linear-gradient($direction, $primary-color, $secondary-color);
  background: -o-linear-gradient($direction, $primary-color, $secondary-color);
  background: linear-gradient($direction, $primary-color, $secondary-color);
}

// Set icon size with their width/height attr. Requirement for mat-icons.
@mixin icon-size($size: $button-icon-size) {
  //font-size: $size;
  //line-height: $size !important;
  //width: $size + 1px !important; // Extra px is to fix letter-spacing override (in _icons.scss) to fix icons display in chrome
  //height: $size + 1px !important; // Make height equal to width. Caused some weird hidden overflow in table icon buttons otherwise.

  --flex-icon-button-icon-size: #{$size};
  width: #{$size};
}

// Fab button
@mixin customFab($size: 22px, $padding: 9px, $shadow: normal) {
  border-radius: 50%;
  width: $size;
  height: $size;
  line-height: 10px;

  @if $shadow == normal {
    box-shadow: 0px 2px 3px -1px rgba(0, 0, 0, 0.2), 0px 1px 4px 0px rgba(0, 0, 0, 0.16), 1px 2px 6px 0px rgba(0, 0, 0, 0.14) !important;
  } @else if $shadow == low {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.1), 0px 1px 1px 0px rgba(0, 0, 0, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.1) !important;
  } @else {
    box-shadow: none !important;
  }

  .mat-button-wrapper {
    padding: 0 !important;
    line-height: 0;
  }

  $icon-size: $size - $padding;

  mat-icon {
    @include icon-size($icon-size);
  }
}

// Set icon sizes
@mixin setIconSizes($default: $body-text) {
  html {
    --flex-icon-button-icon-size: $default;
  }

  .ic-large {
    --flex-icon-button-icon-size: $ic-size-l;
  }

  .ic-xlarge {
    --flex-icon-button-icon-size: $ic-size-xl;
  }
}

// Subtle boxes (light bg + border + colored text)
@mixin status-box($color, $with-arrow: false, $arrow-size: 7px) {
  background-color: rgba($color, 0.1);
  border: solid 1px rgba($color, 0.6);
  color: $color;
  padding: 6px 8px;
  border-radius: $border-radius;
  margin-bottom: $gutter * 0.5;
  font-weight: 600;
  position: relative;

  @if ($with-arrow) {
    &:after {
      content: '';
      position: absolute;
      border-left: $arrow-size solid transparent;
      border-right: $arrow-size solid transparent;
      border-top: $arrow-size solid $color;
      bottom: -$arrow-size;
      right: 50%;
    }
  }
}

// Define (responsive) sizes of dialogs
@mixin dialogSize(
  $extraBreakpoint: false,
  $defaultWidth: 60vw,
  $extraBreakpointWidth: 70vw,
  $tabletWidth: 88vw,
  $setBreakpoint: $widescreen
) {
  width: $defaultWidth;

  @if $extraBreakpoint {
    @media (max-width: #{$setBreakpoint - 1}) {
      max-width: $extraBreakpointWidth !important;
      width: calc(#{$extraBreakpointWidth} - 18px) !important;
    }
  }

  @media (max-width: #{$tablet-width - 1}) {
    max-width: $tabletWidth !important;
    width: calc(#{$tabletWidth} - 18px) !important;
  }
}

@mixin formHeight($height: 40px) {
  $infix-margin-top: $height - 40px;
  $floating-label-spacing: 15px; // Allocate some padding for the floating label

  @include media.min-width('l') {
    .ph-flex-mat-form-field-group-inline {
      height: $height;
    }
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-outline {
      height: $height;
    }

    .mat-form-field-wrapper {
      height: $height;
    }

    .mat-mdc-form-field-flex {
      height: $height;
    }

    .mat-mdc-form-field-infix {
      height: 100%;
      padding: 0;

      display: flex;
      align-items: center;
    }

    .mat-mdc-form-field-label-wrapper {
      height: $height;
      top: -$floating-label-spacing;

      padding-top: $floating-label-spacing;

      .mat-mdc-form-field-label {
        top: calc(50% - 0.5em);
      }
    }

    &.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
      transform: translateY(-$height / 2) scale(0.75);
    }
  }
}
