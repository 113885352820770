@use '../../flex-app-shared/src/lib/core/styles/theme';
@use 'sass:map';
@use '../../flex-app-shared/src/lib/material/font';

.mat-mdc-dialog-container {
  padding-bottom: 0 !important;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-subhead-weight: 600;
  --mdc-dialog-subhead-font: Quicksand, sans-serif;
  --mdc-dialog-subhead-color: rgba(map.get(theme.$ph, color, brand, primary-dark), 85%);

  --mdc-dialog-supporting-text-font: #{map.get(theme.$ph, fontFamilies, running)};
  --mdc-dialog-supporting-text-size: 13px;
  --mdc-dialog-supporting-text-tracking: normal;
  --mdc-dialog-supporting-text-color: black;
  --mdc-dialog-supporting-text-weight: 500;

  --mdc-checkbox-state-layer-size: 36px;

  .mdc-dialog__surface {
    display: flex !important;
  }

  .mat-mdc-dialog-actions {
    justify-content: center;
    margin-bottom: 0;

    .mat-mdc-button-base {
      + .mat-mdc-button-base {
        margin-left: 0;
      }
    }

    > *:not(:last-child) {
      margin-right: $button-spacing;
    }
  }
}

.large-size-dialog {
  @include dialogSize(true, 75vw, 60vw, 88vw);
}

.medium-size-dialog {
  @include dialogSize();
}

.small-size-dialog {
  @include dialogSize(true, 30vw, 50vw, 88vw);
}

.mat-mdc-dialog-content.mdc-dialog__content {
  // Use inline flex so we can use max-height 100% in the contents for scroll overflow, if needed.
  flex-direction: column;
  display: inline-flex;

  // Use margin instead of padding to allow for overflow: clip;
  margin-top: 20px !important;

  // Still use 100% width by default
  width: 100%;

  &.mdc-dialog__content {
    overflow: clip;
    overflow-clip-margin: 20px;
  }

  > .mat-toolbar {
    padding: 0 1px 0 0;
  }

  > .mat-stepper-horizontal {
    // Make the stepper content scrollable in dialogs
    max-height: 100%;
    overflow: auto;
  }
}
