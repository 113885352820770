@use 'sass:map';
@use '../theme';

@mixin mdc-button-colors($theme-color, $font-color, $outlined-font-color: $theme-color) {
  --mdc-text-button-label-text-color: #{$theme-color};

  --mdc-protected-button-label-text-color: #{$font-color};
  --mdc-protected-button-container-color: #{$theme-color};

  --mdc-outlined-button-outline-color: #{$theme-color};
  --mdc-outlined-button-label-text-color: #{$outlined-font-color};

  --mdc-filled-button-label-text-color: #{$font-color};
  --mdc-filled-button-container-color: #{$theme-color};

  --mat-mdc-fab-color: #{$font-color};
  --mdc-fab-container-color: #{$theme-color};

  --mdc-icon-button-icon-color: #{$theme-color};

  --mdc-fab-small-container-color: #{$theme-color};
  --mdc-fab-small-container-shape: 100%;

  --mat-chip-disabled-container-opacity: 0.4;
  --mdc-chip-container-height: 25px;
  --mdc-chip-outline-width: 0;
}

.mat-mdc-icon-button:not(.ph-flex-mat-mdc-icon-button-toolbar) {
  --mdc-icon-button-state-layer-size: 32px;

  > .mat-mdc-button-wrapper {
    display: flex;
    justify-content: center;
  }
}

.mat-mdc-icon-button.ph-flex-mat-mdc-icon-button-toolbar {
  border-radius: map.get(theme.$ph, borders, radius, layout);

  .mat-mdc-icon {
    vertical-align: inherit;
  }
}

.ph-flex-mat-mdc-flat-button-toolbar.ph-flex-mat-mdc-flat-button-toolbar {
  border-radius: map.get(theme.$ph, borders, radius, layout);
}

.mat-mdc-icon-button.ph-flex-mat-mdc-icon-button-medium {
  width: 2.5rem;
  height: 2.5rem;
}

.mat-mdc-mini-fab[disabled],
.mat-mdc-fab[disabled] {
  --mdc-fab-container-color: #{map.get(theme.$ph, color, ui, support, grey-light)};
  --mat-mdc-fab-color: #{map.get(theme.$ph, color, ui, support, grey-dark)};
}

.mat-mdc-icon-button {
  --mdc-icon-button-disabled-icon-color: #{map.get(theme.$ph, color, ui, support, grey-light)} !important; // Override default variable
}

.ph-flex-mat-icon-button-on-dark.mat-mdc-icon-button {
  --mdc-icon-button-disabled-icon-color: #{map.get(theme.$ph, color, ui, support, grey-dark)} !important; // Override default variable
}

.mat-mdc-flat-button.ph-flex-mat-mdc-icon-button-on-dark,
.mat-mdc-icon-button.ph-flex-mat-mdc-icon-button-on-dark,
.mat-mdc-mini-fab.ph-flex-mat-mdc-icon-button-on-dark,
.mdc-button.ph-flex-mat-flat-button-on-dark {
  color: white;
  &,
  &.mat-mdc-unelevated-button:not(:disabled) {
    // Styling for back button
    background-color: map.get(theme.$ph, color, brand, primary-light);
  }

  &:not(:disabled):hover {
    background-color: map.get(theme.$ph, color, brand, primary-light);

    .mat-mdc-icon {
      color: white;
    }
  }

  &.mat-mdc-button-disabled,
  &[disabled] {
    color: map.get(theme.$ph, color, ui, support, grey-dark);
    border-color: map.get(theme.$ph, color, ui, support, grey-dark);

    --mdc-circular-progress-active-indicator-color: map.get(theme.$ph, color, brand, primary);
  }
}

.mat-button-base {
  @include mdc-button-colors(map.get(theme.$ph, color, ui, support, white), map.get(theme.$ph, color, brand, primary));
}

.mat-primary {
  @include mdc-button-colors(map.get(theme.$ph, color, brand, primary), map.get(theme.$ph, color, ui, support, white));

  --mdc-circular-progress-active-indicator-color: white;
}

.mat-accent {
  @include mdc-button-colors(map.get(theme.$ph, color, brand, accent), map.get(theme.$ph, color, ui, support, white));
}

.mat-warn {
  @include mdc-button-colors(map.get(theme.$ph, color, ui, alerts, error), map.get(theme.$ph, color, ui, support, white));
}

// CTA button colors
.mat-cta {
  @include mdc-button-colors(
    map.get(theme.$ph, color, ui, support, cta),
    map.get(theme.$ph, color, brand, primary),
    map.get(theme.$ph, color, brand, primary)
  );
}

// Success button colors
.mat-success {
  @include mdc-button-colors(map.get(theme.$ph, color, ui, alerts, success), map.get(theme.$ph, color, ui, support, white));
}

html {
  --mdc-icon-button-state-layer-size: 32px;

  --mdc-outlined-button-outline-width: 1px;

  --flex-icon-button-icon-size: 14px;



  .mat-mdc-icon-button {
    --mdc-icon-button-icon-size: var(--flex-icon-button-icon-size, 14px);
  }

  --mdc-circular-progress-active-indicator-color: map.get(theme.$ph, color, brand, primary);
}

// Button height
button {
  &.mat-mdc-button,
  &.mat-mdc-flat-button,
  &.mat-mdc-raised-button,
  &.mat-mdc-stroked-button,
  &.mat-mdc-button-toggle-button,
  &.mat-button-toggle-button {
    height: var(--flex-button-toggle-height, 32px);
    line-height: var(--flex-button-toggle-height, 32px);

    > .mat-mdc-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > .mat-mdc-button-toggle-label-content,
    > .mat-button-toggle-label-content {
      height: 32px;
      line-height: 32px;
      --mat-standard-button-toggle-height: 32px;
    }
  }
}

// Button text transform {
button:not(.button-text-only) {
  &.mat-mdc-button,
  &.mat-mdc-unelevated-button,
  &.mat-mdc-flat-button,
  &.mat-mdc-raised-button,
  &.mat-mdc-stroked-button,
  &.mat-mdc-outlined-button {
   --mdc-filled-button-label-text-transform: uppercase;
   --mdc-stroked-button-label-text-transform: uppercase;
   --mdc-raised-button-label-text-transform: uppercase;
   --mdc-flat-button-label-text-transform: uppercase;
    --mdc-outlined-button-label-text-transform: uppercase;
  }
}

// Icons
button {
  &.mat-mdc-button,
  &.mat-mdc-flat-button,
  &.mat-mdc-raised-button,
  &.mat-mdc-stroked-button {
    .mat-mdc-icon {
      @include icon-size(17px);
    }
  }

  &.mat-mdc-icon-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .mat-mdc-icon {
      @include icon-size(14px);

      .mat-datepicker-toggle & {
        // Otherwise this is overridden
        @include icon-size(20px);
      }
    }
  }

  &.mat-mdc-mini-fab-xs {
    .mat-mdc-icon {
      display: flex;
    }
  }
}

// Expandable row
// This is only used in devices component at this time.
// This row has a blue-ish background, and the stroked button is normally transparent (on white background)
.mat-cel.expanded-table-row,
.expanded-table-box,
.panel-box {
  .mat-mdc-stroked-button {
    background: white;
  }
}

.ph-flex-mat-icon-button-toolbar.mdc-icon-button {
  border-radius: 8px;

  &.ph-flex-mat-icon-button-on-dark {
    background-color: #153249;

    &.mat-button-disabled,
    &[disabled] {
      color: #556a7b;
    }
  }
}

.mdc-fab {
  --mdc-fab-container-shape: 50%;
}

.mat-mdc-chip-set {
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
}

.mdc-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
