.top-page-selectors {
  display: flex;

  > * {
    margin-right: 8px;
  }
}

mat-toolbar {
  border-radius: 6px;

  &.card-tooltbar {
    background: rgba(white, 0.4);
  }

  .toolbar-spacer {
    flex: 1 1 auto;
  }

  .pull-right {
    margin-left: auto;
  }

  &.page-header {
    padding: 0 $gutter;

    .page-title {
      .mat-icon {
        @include icon-size($ic-size-xl);
        vertical-align: text-bottom;
        margin-right: 8px;
      }

      > span {
        padding-bottom: 3px;
      }
    }

    .mat-form-field {
      margin-left: $gutter * 0.5;
      font-size: 14px;

      .mat-form-field-wrapper {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }

  .toolbar-form {
    flex: 1;
  }

  .toolbar-actions {
    flex: 1;
    text-align: right;
  }

  &.with-spacing {
    margin-bottom: $gutter * 0.5;
  }
}
