@use 'sass:map';
@use '@angular/material' as mat;
@use '../src/lib/core/styles/media';
@use '../src/lib/core/styles/theme';
@use '../src/lib/material/layout';

body,
html {
  @include mat.m2-typography-level($flex-app-typography, body-1);
  --mat-table-row-item-label-text-line-heigh: 18px;
}

p {
  margin: $gutter * 0.5 0;
}

.page-content {
  display: inline-flex;
  flex-direction: column;
  flex: 1 1 auto;

  > *:not(router-outlet) {
    flex: 1 1 auto;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }
}

@include media.max-width('m') {
  .page-content {
    width: 100%;
  }
}

// Page layout
.page-header.mat-toolbar {
  background: none;
  padding: 0 $gutter !important;
  margin: $gutter * 0.5 0 $gutter * 0.25 0;
}

// Drawer
.mat-drawer-container {
  --mat-sidenav-content-background-color: #{mat.m2-get-color-from-palette($md-brightgrey, 500)};
}

// Overlay
.cdk-overlay-dark-backdrop {
  background: rgba(mat.m2-get-color-from-palette($md-primary, 500), 0.85);
}

// To cut off large tables to not expand outside the mat-mdc-cards.
.overflow-table {
  overflow: auto;
  padding-bottom: 1px;

  > table {
    td {
      white-space: nowrap;
    }
  }

  &.has-shadow {
    box-shadow: 2px 2px 6px 0px rgba(mat.m2-get-color-from-palette($md-lightgrey, 400), 0.2);
  }
}

// Flex row layout
.flex-row {
  @include flex-row();

  // More compact layout
  &.compact {
    @include flex-row($gutter-size: $gutter * 0.5);
  }

  // Flex row layout, not responsive
  &__not-responsive {
    @include flex-row($responsive: false);
  }

  // Flex row layout, tablet breakpoint
  &__tablet-breakpoint {
    @include flex-row($responsive-to-column: $tablet-width);
  }
}

// Flex grid layout
.flex-grid {
  @for $cols from 1 through 4 {
    &.cols-#{$cols} {
      @include flex-grid($cols, $height: 100%, $vspacing: 2px, $hspacing: $gutter * 0.25);
    }
  }

  &.half-gutter-space {
    @for $cols from 1 through 4 {
      &.cols-#{$cols} {
        @include flex-grid($cols, $height: 100%, $vspacing: 2px, $hspacing: $gutter * 0.5);
      }
    }
  }

  &.gutter-space {
    @for $cols from 1 through 4 {
      &.cols-#{$cols} {
        @include flex-grid($cols, $height: 100%, $vspacing: 2px, $hspacing: $gutter);
      }
    }
  }
}

// Apply flex grid to Dashboards
.dashboard {
  // Customer dashboard - smaller size widgets (less data)
  &.customer {
    @for $cols from 1 through 4 {
      &.cols-#{$cols} {
        @include flex-grid($cols, $height: 274px, $vspacing: $gutter * 0.25, $hspacing: $gutter * 0.25);
      }
    }
  }

  // Employee dashboard (internal) - bigger, more data driven, widgets.
  &.internal {
    @for $cols from 1 through 4 {
      &.cols-#{$cols} {
        @include flex-grid(
          $cols,
          $gutter * 0.5,
          $height: calc(100% - (#{$gutter} * 2)),
          $vspacing: $gutter * 0.25,
          $hspacing: $gutter * 0.25
        );
      }
    }
  }
}

// Visual segment within a card
.segment-block {
  border: solid 1px rgba(mat.m2-get-color-from-palette($md-mediumgrey, 100), 0.75);
  padding: $gutter;
  background-image: linear-gradient(
    rgba(mat.m2-get-color-from-palette($md-lightgrey, 50), 0.3),
    rgba(mat.m2-get-color-from-palette($md-lightgrey, 50), 0.8)
  );
  border-radius: 6px;
  box-shadow: 2px 2px 6px 0px rgba(mat.m2-get-color-from-palette($md-lightgrey, 400), 0.2);
  overflow: auto;
  box-sizing: border-box;

  .col-1 {
    padding-bottom: 0;
  }
}

// Apply single column flexbox to this class
.single-column-form {
  display: flex;
  flex-direction: column;

  > * {
    padding: 2px 0;
  }
}

// Containter to make tables display better within a (colored) block (white background + padding).
.boxed-table-container {
  padding: 10px 15px;
  background: white;
  border-radius: 3px;
  border: solid 1px mat.m2-get-color-from-palette($md-secondary, 100);

  > table {
    border: none !important;
  }

  > .mat-paginator {
    border: none !important;

    > .mat-paginator-outer-container {
      background: white;
      padding: 0 10px;
    }
  }
}

// Custom override to add customer selection input to page title.
.injected-page-title-and-nav {
  margin-top: -68px;

  .page-title {
    margin-bottom: $gutter * 0.5;
    margin-top: 8px;

    > form {
      display: flex;
      align-items: center;
    }

    .mat-icon {
      @include icon-size($ic-size-xl);
      margin-right: 8px;
    }

    > span {
      padding-bottom: 3px;
    }

    .title-text {
      margin-right: $gutter * 0.5;
      @include mat.m2-typography-level($flex-app-typography, headline-1);
    }

    .customer-input {
      @include mat.m2-typography-level($flex-app-typography, subtitle-2);
      padding: 5px 10px 6px 10px;
      margin-top: 2px;
      margin-left: -5px;
      margin-right: $gutter;
      display: block;
      color: #667a8b;
      background-color: #f3f8fc;
      border-radius: 3px;
      box-sizing: border-box;
      flex: 1;
      min-width: 250px;
      border: solid 1px mat.m2-get-color-from-palette($md-mediumgrey, 100);
    }
  }
}

.customer-info {
  line-height: 26px;
  margin-bottom: $gutter * 0.5;

  &.__spacer {
    margin-bottom: $gutter * 0.5;
  }
}

.versioning {
  font-size: 11px;
  position: absolute;
  bottom: 0;
  left: $gutter * 0.5;
  width: 190px;
  padding: $gutter $gutter * 0.5 $gutter * 0.5 $gutter * 0.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: mat.m2-get-color-from-palette($md-primary, 500)
    linear-gradient(
      180deg,
      rgba(mat.m2-get-color-from-palette($md-primary, 500), 0) 0%,
      rgba(mat.m2-get-color-from-palette($md-primary, 500), 1) 25%,
      rgba(mat.m2-get-color-from-palette($md-primary, 500), 1) 100%
    );
  color: rgba(white, 0.8);

  span:hover {
    color: rgba(white, 1);
  }
}

form > .mat-grid-list .mat-figure {
  justify-content: start;
}

.flex-col {
  flex-direction: column;
  margin-bottom: 0;
  height: auto;
  min-height: 100%;

  *[class^='col-']:not(:last-of-type) {
    margin-right: 0 !important;
    margin-bottom: $gutter * 0.5;
  }
}

.process-track {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5;
  background: mat.m2-get-color-from-palette($md-mediumgrey, 500);
  width: 100%;

  > span {
    height: 3px;
    float: left;
  }

  .track-neutral {
    background: mat.m2-get-color-from-palette($md-digitallightblue, 500);
  }

  .track-success {
    background: mat.m2-get-color-from-palette($md-success, 500);
  }

  .track-warn {
    background: mat.m2-get-color-from-palette($md-warn, 500);
  }

  .track-alert {
    background: mat.m2-get-color-from-palette($md-alert, 500);
  }
}

.sticky {
  position: fixed;
  top: 0;
  overflow: hidden;
  z-index: 1000;
}

.block {
  display: block;
  width: 100%;
}

.filter-bar {
  @include mat.m2-typography-level($flex-app-typography, body-1);
  background-color: transparent;
  display: flex;
  padding: 1 !important;

  @include media.with-x-break-points(map.get(theme.$ph, components, filter-bar, min-height)) using ($value) {
    min-height: $value;
  }

  margin-bottom: $gutter * 0.5;
  align-items: flex-start !important;

  > * {
    flex: 1;

    &.minimize {
      flex: 0 0 auto;
      display: inline-flex;
      align-items: center;

      height: 32px;

      > button,
      > ph-flex-save-button {
        margin-left: $gutter * 0.5;
      }
    }
  }

  > *:not(:first-child) {
    margin-left: $gutter * 0.5;
  }

  ph-flex-table-filter {
    width: 100%;
  }

  &.checkbox-row {
    font-weight: 600;
    height: 22px;
    margin-bottom: $gutter * 0.5;

    .mat-checkbox {
      margin-left: 4px;
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin-top: inherit;
  }
}

.panel-box {
  border: solid 1px rgba(mat.m2-get-color-from-palette($md-secondary, 500), 0.25);
  @include gradient(
    to right,
    rgba(mat.m2-get-color-from-palette($md-secondary, 500), 0.06),
    rgba(mat.m2-get-color-from-palette($md-secondary, 500), 0.12)
  );
  padding: $gutter;
  position: relative;
  border-radius: $border-radius;

  .mat-form-field-appearance-outline .mat-form-field-outline {
    top: 0 !important;
    background: white;
  }

  .button-bar {
    margin-top: $gutter;
  }

  .mat-mdc-table {
    border: solid 1px rgba(mat.m2-get-color-from-palette($md-mediumgrey, 100), 0.75);
    border-bottom: none;
  }

  .mat-paginator {
    border: solid 1px rgba(mat.m2-get-color-from-palette($md-mediumgrey, 100), 0.75);
    border-top: none;
  }
}

.labeled-data {
  display: inline-flex;
  margin-right: $gutter * 0.5;

  > span {
    padding: 3px 8px;
    border: solid 1px mat.m2-get-color-from-palette($md-mediumgrey, 300);
    font-weight: 400;
    background: white;
    display: inline-flex;
    align-items: center;

    .mat-icon {
      @include icon-size(16px);
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .label {
    font-weight: 500;
    color: white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: mat.m2-get-color-from-palette($md-mediumgrey, 300);
  }
}

.loading-backdrop {
  background-color: white;
  opacity: 0.7 !important;
}

.summary-box {
  background: mat.m2-get-color-from-palette($md-digitallightblue, 50);
  padding: $gutter * 0.5 $gutter;
  font-size: 15px;
  border-radius: $border-radius;
}
