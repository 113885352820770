@use '@angular/material' as mat;
@use 'sass:map';
@use '../src/lib/core/styles/theme';

table {
  width: calc(100% - 1px); // 1px border causes the tablet to be wider otherwise
  border-collapse: collapse;
  --flex-button-toggle-height: 25px;
  --flex-chip-container-height: 25px;
  --flex-icon-button-icon-size: 20px;
  --mdc-checkbox-state-layer-size: 25px;

  background: white; // Provide white background by default, otherwise it is transparent on odd-colored backgrounds.

  .mat-mdc-icon-button {
    --mdc-icon-button-state-layer-size: 25px !important;

    .mat-mdc-button-touch-target {
      width: 25px; // Make sure the touch target does not overlap with adjacent buttons
      height: 30px; // Row height
    }
  }

  &:not(.mat-calendar-table):not(.ph-g-table) {
    th,
    td {
      text-align: left;

      border-bottom: 1px solid #09273f1f !important;

      &:not(.td-with-button):not(.mat-column-quarter) {
        padding: 0 $gutter * 0.25 !important;
      }

      &.td-with-button {
        padding: 0 !important;

        button {
          width: 100%;
        }
      }

      &.mat-column-quarter {
        padding: 7px 4px !important;
      }

      > .mat-icon,
      > ph-flex-vertical-center > .mat-icon {
        @include icon-size($ic-size-s);
        white-space: normal !important;
      }

      .mat-icon-button {
        $size: 17px;
        width: $size + 1px;
        height: $size + 1px;
        line-height: 12px;

        .mat-icon {
          // TODO replace?
          font-size: $size !important;
          width: $size !important;
        }

        &.bigger {
          $size: 20px;
          width: $size + 1px;
          height: $size + 1px;
          line-height: 12px;

          .mat-icon {
            font-size: $size !important;
            width: $size !important;
          }
        }

        &:not(.more-menu) {
          margin-left: 4px;
        }
      }

      &.value {
        text-align: right;
      }

      &.text {
        text-align: left !important;
      }

      &.center {
        text-align: center !important;
      }

      &.single-icon {
        width: 1px;
      }

      &.status-alert {
        > .mat-icon {
          margin-left: 6px;
        }
      }
      &.label-cell {
        white-space: nowrap;
        width: 1px;
        font-weight: 500;
        padding-right: $gutter * 0.5 !important;
        padding-left: 0 !important;
      }

      &.icon-cell {
        width: 1px;
      }
    }
    tr {
      --mat-table-row-item-label-text-size: 13px; // Use old 13px body styling
      --mat-table-row-item-label-text-font: #{map.get(theme.$ph, fontFamilies, running)};

      // Even row highlight
      &:nth-child(odd):not(.detail-row):not(.active-expanded-row):not(.mat-mdc-header-row) {
        background: rgba(mat.m2-get-color-from-palette($md-brightgrey, 500), 0.35);
      }

      &.mat-mdc-footer-row {
        --mat-table-footer-supporting-text-size: 13px;
        --mat-table-footer-supporting-text-font: #{map.get(theme.$ph, fontFamilies, running)};
      }

      &.mat-mdc-header-row {
        height: $table-row-height;
        --mat-table-header-headline-size: 13px;

        .mdc-data-table__header-cell {
          color: mat.m2-get-color-from-palette($md-mediumgrey, 300);
          font-weight: 500;

          .mat-icon {
            display: inline-flex;
          }

          &.upwards {
            color: white;
            background-color: mat.m2-get-color-from-palette($md-energylight, 500);
            font-weight: 300;
          }

          &.downwards {
            color: white;
            background-color: mat.m2-get-color-from-palette($md-energydark, 500);
            font-weight: 300;
          }

          &.neutral {
            background: rgba(mat.m2-get-color-from-palette($md-brightgrey, 500), 0.4);
          }
        }

        &.caption {
          background: rgba(mat.m2-get-color-from-palette($md-mediumgrey, 500), 0.15);

          th,
          td {
            text-align: center;
            font-weight: 500;
          }
        }

        &.sub-caption {
          background: rgba(mat.m2-get-color-from-palette($md-brightgrey, 500), 0.8);

          th,
          td {
            font-weight: 200;
            text-align: center;
          }
        }
      }

      &.mat-mdc-row {
        &:not(.detail-row) {
          height: $table-row-height;
        }

        .mdc-data-table__cell {
          &.expanded-table-row {
            border: solid 1px rgba(mat.m2-get-color-from-palette($md-secondary, 500), 0.2);
            border-top: none;
            border-bottom: none;
            padding-left: 0 !important;
            @include gradient(
              to bottom,
              rgba(mat.m2-get-color-from-palette($md-secondary, 500), 0.12),
              rgba(mat.m2-get-color-from-palette($md-secondary, 500), 0.15)
            );
          }

          &.upwards {
            box-shadow: inset 2px 0 0 0 mat.m2-get-color-from-palette($md-energylight, 500);
          }

          &.downwards {
            box-shadow: inset 2px 0 0 0 mat.m2-get-color-from-palette($md-energydark, 500);
          }

          &.neutral {
            box-shadow: inset 2px 0 0 0 mat.m2-get-color-from-palette($md-brightgrey, 500);
          }

          .mat-mdc-card {
            padding: $gutter * 0.5 !important;

            .mat-mdc-card-title {
              font-size: 16px;
              margin-bottom: 4px;
            }
          }

          &.status-alert {
            a {
              box-shadow: inset 0px -1px 0 0 mat.m2-get-color-from-palette($md-alert, 500);
              background-color: rgba(mat.m2-get-color-from-palette($md-alert, 500), 0.1);
              margin: 0 -4px;
              padding: 0 4px;
            }
          }
        }

        &.active-expanded-row {
          @include gradient(
            to bottom,
            rgba(mat.m2-get-color-from-palette($md-secondary, 500), 0.08),
            rgba(mat.m2-get-color-from-palette($md-secondary, 500), 0.12)
          );
          border-bottom: none;

          > .mdc-data-table__cell {
            border-bottom: none;

            &:first-child {
              border-left: solid 1px rgba(mat.m2-get-color-from-palette($md-secondary, 500), 0.2);
            }

            &:last-child {
              border-right: solid 1px rgba(mat.m2-get-color-from-palette($md-secondary, 500), 0.2);
            }
          }
        }
      }
    }
  }

  // Table property classes
  &.has-hover {
    > tbody > tr:hover {
      > td:not(.expanded-table-row) {
        cursor: pointer;
        background: rgba(mat.m2-get-color-from-palette($md-secondary, 500), 0.06);
      }
    }
  }

  &.has-actions {
    th:last-of-type,
    td:last-of-type {
      width: 1px;
      white-space: nowrap;
    }
  }

  &.has-checkboxes {
    th:first-of-type,
    td:first-of-type {
      width: 1px;
      padding-left: 0 !important;
    }
  }

  &.has-no-hor-bandedrows {
    tr:nth-child(odd):not(.mat-mdc-header-row) {
      background: none !important;
    }
  }

  &.has-expandedrows {
    tbody > tr {
      &.expandable-table-row {
        // only apply to to the expandable row, prevent deeper childs (the expanded rows themselves)
        &:nth-child(4n + 3) {
          // Assume 1 row + 1 expanded row, highlight all even standard rows
          background: white !important;
        }
      }
    }
  }

  &.has-hor-separator {
    tr.mat-mdc-row td.mdc-data-table__cell {
      border-bottom: solid 1px mat.m2-get-color-from-palette($md-lightgrey, 100);
    }
  }

  &.has-values {
    tr td {
      text-align: right;
    }
  }

  &.is-condensed {
    tr {
      height: $table-row-height-condensed;

      *[class^='ic-'] {
        &:before {
          font-size: 17px;
          line-height: $table-row-height-condensed - 1px;
        }
      }
    }
  }

  &.has-nowrap {
    white-space: nowrap;
  }

  &.has-fixed-size-cols {
    table-layout: fixed;
  }

  &.has-shadow {
    box-shadow: 2px 2px 6px 0 rgba(mat.m2-get-color-from-palette($md-lightgrey, 400), 0.2);
  }

  &.is-for-grid {
    tr {
      border-bottom: solid 1px mat.m2-get-color-from-palette($md-brightgrey, 500);

      &:nth-child(odd) {
        background: white !important;
      }

      td {
        padding: 8px 6px !important;

        .mat-icon {
          vertical-align: middle;
        }
      }
    }
  }
}

.widget {
  table {
    .mat-mdc-row {
      .mdc-data-table__cell {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 155px;
      }
    }
  }
}

.expanded-table-box {
  width: 100%;

  .mat-mdc-card {
    box-shadow: none;
    background: none;
    border-bottom: solid 1px rgba(mat.m2-get-color-from-palette($md-secondary, 500), 0.2);
    height: 100% !important;
  }
}

//TODO: Refactor all, but wait until clear what this screen is going to be.
$ph_accent_color: #b18c00;

.period-selection {
  padding: $gutter 0;

  .__controls {
    padding: $gutter * 0.5 0;
    height: 38px;
    display: flex;
    justify-content: flex-end;

    .__button {
      margin-left: $gutter * 0.5;
    }
  }

  .__table {
    border-collapse: collapse;

    .__cell {
      padding: 0;
      margin: 0;
      width: 8%;

      &:not(.-selected) {
        border: 1px solid transparent;
      }

      &.-years {
        box-sizing: border-box;
        text-align: left;
        width: 4%;
        border: solid 1px mat.m2-get-color-from-palette($md-mediumgrey, 100);
        padding: 0 $gutter * 0.5;
        background: rgba(mat.m2-get-color-from-palette($md-primary, 500), 0.8);
        color: white;
      }

      &.-month {
        text-align: center;
        cursor: not-allowed;
        border: solid 1px mat.m2-get-color-from-palette($md-mediumgrey, 100);
        background-color: white;
        color: mat.m2-get-color-from-palette($md-mediumgrey, 500);

        .__item {
          cursor: not-allowed;
        }

        &.-date-in-contract {
          color: #284258;
          cursor: pointer;
          border: solid 1px mat.m2-get-color-from-palette($md-mediumgrey, 100);
          background-color: rgba(mat.m2-get-color-from-palette($md-phgold, 200), 0.2);
          transition: background-color 0.35s ease-in-out, border-color 0.1s ease-in-out;

          .__item {
            cursor: pointer;
            border: solid 1px mat.m2-get-color-from-palette($md-mediumgrey, 100);

            &[disabled='disabled'],
            &:disabled {
              cursor: not-allowed;
              border: dotted 1px mat.m2-get-color-from-palette($md-mediumgrey, 100);
            }
          }

          &:hover {
            background-color: rgba(mat.m2-get-color-from-palette($md-phgold, 200), 0.6);
          }

          &:focus {
            background-color: rgba(mat.m2-get-color-from-palette($md-phgold, 200), 0.6);
          }
        }

        &.-in-range {
          background-color: lighten($ph_accent_color, 55);
          border: 0 !important;
          border-top: solid 1px white !important;
          border-bottom: solid 1px white !important;

          &:hover {
            background-color: lighten($ph_accent_color, 60);
          }

          .__item {
            color: inherit;
          }
        }

        &.-start,
        &.-start.-in-range {
          background-color: lighten($ph_accent_color, 50);
          border-left: solid 2px white !important;

          &:hover {
            background-color: lighten($ph_accent_color, 52);
          }
        }

        &.-end,
        &.-end.-in-range {
          background-color: lighten($ph_accent_color, 50);
          border-right: solid 1px white !important;

          &:hover {
            background-color: lighten($ph_accent_color, 52);
          }
        }
      }
    }

    &.-period-selected {
      .__cell {
        &.-month {
          opacity: 0.5;

          &.-selected {
            opacity: 1;
            z-index: 1;
            box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.2);
          }
        }
      }
    }

    .table.-period-split {
      .table__cell {
        &.-month {
          opacity: 0.5;

          &:hover {
            opacity: 1;
          }

          &.-in-range {
            .table__item {
              cursor: crosshair;
            }
          }
        }
      }
    }

    .__item {
      border: none !important;
      background: transparent;
      padding: 0;
      margin: 0;
      width: 100%;
      height: 30px;
      cursor: pointer;

      &[disabled='disabled'],
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}

.periods-list-wrapper {
  padding: 15px 0;
}

.period-list-item {
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;

  &__button {
    &.-select {
      &:hover {
        background-color: mat.m2-get-color-from-palette($md-mediumgrey, 50);
      }
    }

    &.-delete {
      padding: 0 4px 0 12px;
      min-width: 44px;

      &:hover {
        background-color: rgba(mat.m2-get-color-from-palette($md-alert, 50), 0.5);
        color: mat.m2-get-color-from-palette($md-alert, 500);
      }
    }
  }

  &__icon {
    margin-right: 5px;
  }
}

.first-header .mat-mdc-table-sticky {
  top: 64px !important;
}

.second-header .mat-mdc-table-sticky {
  top: 90px !important;
}

[mat-sort-header].cdk-keyboard-focused .mat-sort-header-container,
[mat-sort-header].cdk-program-focused .mat-sort-header-container {
  // Do not show the annoying focus border
  border-bottom: none !important;
}
