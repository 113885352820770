@use '@angular/material' as mat;
a {
  color: mat.m2-get-color-from-palette($md-digitaldarkblue, 500);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.pointer {
    // Normally anchors would have pointers, since a[href] would be styled this way
    // In case we do not have a href (yet) we can use this class to force pointer behavior
    cursor: pointer;
  }
}
