@use '@angular/material' as mat;

html {
  --flex-chip-container-height: 32px;
}

.mat-standard-chip {
  margin: 0 0 0 4px !important;
  min-height: 23px !important;
  padding: 3px 12px 7px 12px !important;

  &:last-of-type {
    margin-right: 0 !important;
  }
}

.mat-mdc-chip {
  line-height: 1;
  --mdc-chip-container-height: var(--flex-chip-container-height);

  &.active {
    background-color: mat.m2-get-color-from-palette($md-success, 500) !important;

    &.mat-mdc-chip-disabled {
      opacity: 1;
    }
  }

  &.inactive {
    background-color: mat.m2-get-color-from-palette($md-primary, 400) !important;
  }

  &.select-item-chip {
    background-color: mat.m2-get-color-from-palette($md-primary, 500);
    color: white;
    min-height: 30px !important;
    font-weight: 500;
  }
}

//Custom chip
.chip {
  display: inline-block;
  color: white;
  text-transform: uppercase;
  border-radius: 3px;
  padding: 3px 6px 4px 6px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  width: 26px;
  text-align: center;
}

.environment-tag {
  padding: 6px 12px;
  border-radius: 12px;
  color: white;
  margin: 5px $gutter * 0.5 0 $gutter * 0.5;
  text-align: center;

  &.test {
    background-color: mat.m2-get-color-from-palette($md-digitallightblue, 500);
  }

  &.acceptance {
    background-color: mat.m2-get-color-from-palette($md-success, 500);
  }
}

input.mat-mdc-chip-input {
  width: auto !important;
}
