.mat-paginator {
  background: none !important;

  .mat-paginator-container {
    padding: 0;
  }
}

// Paginator, make page select more compact.
.mat-paginator-page-size-select {
  margin: 0 !important;
  line-height: 1.35 !important;

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
    margin: 0 !important;
  }

  &.mat-form-field-appearance-standard {
    .mat-mdc-form-field-flex {
      padding-top: 0;
    }

    .mat-form-field-infix {
      border-top: 0;
    }

    .mat-form-field-underline {
      bottom: 0;
    }
  }
}

.mat-mdc-paginator-container {
  --mat-paginator-container-size: 56px;
  font-size: 12px;
  color: #09273f8a; // Old theme color

  .mat-mdc-select {
    line-height: 23px;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .mat-mdc-text-field-wrapper {
    padding: 0;
  }

  .mat-mdc-select-value-text {
    color: #09273fde;
  }

  .mat-mdc-paginator-page-size-select {
    width: 54px;
  }

  --flex-icon-button-icon-size: 28px;
}
