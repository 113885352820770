// Dispatching schedule
app-legend-item {
  &:not(:last-child) {
    margin-right: $gutter;
    margin-bottom: $gutter * 0.5;
  }
}

.legend-item {
  min-width: 90px;
  white-space: nowrap;

  &__state-container {
    border-radius: 3px;
    overflow: hidden;
  }

  &__title {
    padding-bottom: 4px;
    opacity: 0.85;
    font-size: 12px;
    font-weight: 500;
  }
  &__state-item {
    text-align: center;
    padding: 3px 15px 4px 15px;
  }
}
