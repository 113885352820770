@use 'sass:math';

@use 'sass:map';
@use '../theme';

@mixin text-crop($line-height: 1.3, $top-adjustment: 0px, $bottom-adjustment: 0px) {
  // Configured in Step 1
  $top-crop: 4;
  $bottom-crop: 3;
  $crop-font-size: 16;
  $crop-line-height: 1.2;

  // Apply values to calculate em-based margins that work with any font size
  $dynamic-top-crop: math.div(max(($top-crop + ($line-height - $crop-line-height) * ($crop-font-size * 0.5)), 0), $crop-font-size);
  $dynamic-bottom-crop: math.div(max(($bottom-crop + ($line-height - $crop-line-height) * ($crop-font-size * 0.5)), 0), $crop-font-size);

  // Mixin output
  line-height: $line-height;

  &::before,
  &::after {
    content: '';
    display: block;
    height: 0;
    width: 0;
  }

  &::before {
    margin-bottom: calc(-#{$dynamic-top-crop}em + #{$top-adjustment});
  }

  &::after {
    margin-top: calc(-#{$dynamic-bottom-crop}em + #{$bottom-adjustment});
  }
}
// Mixin generated at: http://text-crop.eightshapes.com/?typeface-selection=google-font&typeface=Quicksand&custom-typeface-name=Helvetica&custom-typeface-url=&custom-typeface-weight=400&custom-typeface-style=normal&weight-and-style=300&size=16&line-height=1.2&top-crop=4&bottom-crop=3
// Note: When generating new text-crop mixin, apply math.div to avoid breaking change https://sass-lang.com/documentation/breaking-changes/slash-div

/**
  Ellipsis requires 2 elements, the container that has this mixin and a child with text-crop.
  Offset defines the extra room overflowing text elements get above and below the block, so characters like 'g' do not get truncated.
  This value is dependant on the font size and line height, but 10 should be sufficient in most cases.
 */
@mixin text-crop-ellipsis-container($offset: 10px) {
  padding-top: $offset;
  margin-top: -$offset;

  padding-bottom: $offset;
  margin-bottom: -$offset;

  overflow: hidden;
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
