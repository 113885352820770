@use '@angular/material' as mat;

.mat-ink-bar {
  background-color: mat.m2-get-color-from-palette($md-digitaldarkblue, 500) !important;
}

.mat-mdc-tab-header {
  top: -46px; // offset top
  background: white;
  position: absolute !important;
}
