@use '@angular/material' as mat;
.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #{mat.m2-get-color-from-palette($md-digitaldarkblue, 700)};
  --mdc-snackbar-supporting-text-color: white;
  --mat-mdc-snack-bar-button-color: white;
  --mat-snack-bar-button-color: white;

  &.error {
    --mdc-snackbar-container-color: #{mat.m2-get-color-from-palette($md-alert, 700)};
  }

  .mat-mdc-snack-bar-action {
    background: #{mat.m2-get-color-from-palette($md-primary, 900)};
  }

  .mat-simple-snackbar {
    // Snackbar component that is used when you only provide text
    text-align: center;
    > span {
      flex: 1 1 auto;
    }
  }
}

// STATUSES
.status-success {
  color: mat.m2-get-color-from-palette($md-success, 800);
}
.status-alert {
  color: mat.m2-get-color-from-palette($md-alert, 500);
}
.status-warn {
  color: mat.m2-get-color-from-palette($md-warn, 500);
}
.status-on {
  color: mat.m2-get-color-from-palette($md-autoon, 400);
}
.status-off {
  color: mat.m2-get-color-from-palette($md-off, 700);
}
.status-up {
  color: mat.m2-get-color-from-palette($md-energylight, 500);
}
.status-down {
  color: mat.m2-get-color-from-palette($md-energydark, 500);
}
.status-play {
  color: mat.m2-get-color-from-palette($md-status-play, 500);
}
.status-pause {
  color: mat.m2-get-color-from-palette($md-status-pause, 500);
}
.status-stop {
  color: mat.m2-get-color-from-palette($md-status-stop, 500);
}
.status-highlight,
.status-info {
  color: mat.m2-get-color-from-palette($md-digitallightblue, 500);
}
.status-neutral {
  color: mat.m2-get-color-from-palette($md-digitaldarkblue, 500);
}
.status-passive {
  color: mat.m2-get-color-from-palette($md-mediumgrey, 200);
}
.status-powerhouse {
  color: mat.m2-get-color-from-palette($md-phgold, 500);
}
.status-primary {
  color: mat.m2-get-color-from-palette($md-primary, 500);
}
.status-secondary {
  color: mat.m2-get-color-from-palette($md-secondary, 500);
}

// STATUS BOXES
// Solid color + white text.
.status-box-primary {
  background-color: mat.m2-get-color-from-palette($md-primary, 500);
  color: white;
}
.status-box-success {
  background-color: mat.m2-get-color-from-palette($md-success, 500);
  color: white;
}
.status-box-buy {
  background: mat.m2-get-color-from-palette($md-financelight, 500);
  color: white;
}
.status-box-sell {
  background: mat.m2-get-color-from-palette($md-financedark, 500);
  color: white;
}
.status-box-on {
  background: mat.m2-get-color-from-palette($md-autoon, 500);
  color: white;
}
.status-box-off {
  background: mat.m2-get-color-from-palette($md-off, 500);
  color: white;
}

.status-subtle-box-alert-with-arrow {
  @include status-box(mat.m2-get-color-from-palette($md-alert, 500), true);
}
