@use '@angular/material' as mat;
@use 'sass:map';
@use '../src/lib/material/font';
@use '../src/lib/core/styles/theme';

.mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: white;
  padding: $gutter;
  display: flex;
  margin-bottom: $gutter;

  .mat-mdc-card-title {
    margin-bottom: $gutter * 0.5;

    .mat-form-field {
      margin-top: -8px;
    }
  }

  .mat-mdc-card-subtitle {
    color: #09273f8a; // Old theme color
    padding-bottom: 16px;
  }

  > :last-child:not(.mat-mdc-card-footer) {
    margin-bottom: 0;
  }

  .mat-mdc-card-content {
    padding: 0 !important;
    padding-top: 10px !important; // Add some overflow space for floating labels
    margin-top: -10px;

    margin-bottom: 16px;

    > :first-child {
      margin-top: 0;
    }

    .mat-toolbar {
      padding: 0 1px 0 0;
    }
  }

  .mat-mdc-card-header {
    padding: 0;

    .mat-mdc-icon-button {
      padding: 0;
    }

    .mat-mdc-card-header-text {
      flex: 1 0 auto;
    }
  }

  .mat-mdc-card-header-text {
    margin: 0 !important;
  }

  .mat-mdc-card-actions {
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;

    > * {
      margin: 0 !important;

      &:not(:last-child) {
        margin-right: $button-spacing !important;
      }
    }

    ph-flex-entity-save-button {
      margin-left: $button-spacing;
    }

    &.fixed-to-bottom-actions {
      position: absolute;
      bottom: $gutter;
      right: $gutter;
      padding: 0;
    }
  }
  .mat-mdc-card-footer {
    &.fixed-to-bottom-actions-helper {
      height: 30px;
    }
  }

  &.with-tabs {
    $tab-row-height: 40px;
    $tab-indent: 20px;

    margin-top: $tab-row-height;

    .mat-tab-header {
      margin-top: -$tab-row-height;
      padding-left: $tab-indent;
      border-bottom: none;

      .mat-ink-bar {
        display: none;
      }

      .mat-tab-label-container {
        //margin-right: $tab-indent;
      }

      .mat-tab-header-pagination-before {
        margin-left: -20px;
      }

      .mat-tab-header-pagination-after {
        //margin-right: 20px;
      }
    }
  }
}

.mat-mdc-card-header .mat-mdc-card-title,
.mat-mdc-card-title {
  --mat-card-title-text-font: map.get(theme.$ph, fontFamilies, headings);
  --mat-card-title-text-line-height: 25px;
  --mat-card-title-text-size: 16px;
  --mat-card-title-text-weight: 600;

  @include mat.m2-typography-level($flex-app-typography, subtitle-1);
}

.mdc-card__actions {
  min-height: 32px !important;
}
