@use '@angular/material' as mat;

$flex-app-primary: mat.m2-define-palette($md-primary);
$flex-app-accent: mat.m2-define-palette($md-secondary);
$flex-app-warn: mat.m2-define-palette($md-alert);

// Foreground palette for light themes.
$light-theme-foreground-palette: (
  base: mat.m2-get-color-from-palette($md-text, 500),
  divider: rgba(mat.m2-get-color-from-palette($md-text, 500), 0.12),
  dividers: rgba(mat.m2-get-color-from-palette($md-text, 500), 0.12),
  disabled: rgba(mat.m2-get-color-from-palette($md-text, 500), 0.38),
  disabled-button: rgba(mat.m2-get-color-from-palette($md-text, 500), 0.38),
  disabled-text: rgba(mat.m2-get-color-from-palette($md-text, 500), 0.38),
  hint-text: rgba(mat.m2-get-color-from-palette($md-text, 500), 0.38),
  secondary-text: rgba(mat.m2-get-color-from-palette($md-text, 500), 0.54),
  icon: rgba(mat.m2-get-color-from-palette($md-text, 500), 0.54),
  icons: rgba(mat.m2-get-color-from-palette($md-text, 500), 0.54),
  text: rgba(mat.m2-get-color-from-palette($md-text, 500), 0.87),
  slider-off: rgba(mat.m2-get-color-from-palette($md-text, 500), 0.26),
  slider-off-active: rgba(mat.m2-get-color-from-palette($md-text, 500), 0.38)
);

$flex-app-theme: (
  color: (
    primary: $flex-app-primary,
    accent: $flex-app-accent,
    warn: $flex-app-warn,
    is-dark: false,
    foreground: mat.$m2-light-theme-foreground-palette,
    background: mat.$m2-light-theme-background-palette
  )
);

$flex-app-theme-dark: (
  color: (
    primary: $flex-app-primary,
    accent: $flex-app-accent,
    warn: $flex-app-warn,
    is-dark: true,
    foreground: mat.$m2-dark-theme-foreground-palette,
    background: mat.$m2-dark-theme-background-palette
  )
);

@include mat.core();
@include mat.core-theme($flex-app-theme);

@include mat.datepicker-theme($flex-app-theme);
@include mat.stepper-theme($flex-app-theme);
@include mat.bottom-sheet-theme($flex-app-theme);
@include mat.badge-theme($flex-app-theme);
@include mat.button-toggle-theme($flex-app-theme);
@include mat.divider-theme($flex-app-theme);
@include mat.snack-bar-theme($flex-app-theme);

.mat-drawer-content {
  min-height: 100vh;

  display: flex !important;
  flex-direction: column;
}

.sidebar-theme {
  @include mat.datepicker-theme($flex-app-theme-dark);
  @include mat.stepper-theme($flex-app-theme-dark);
  @include mat.bottom-sheet-theme($flex-app-theme-dark);
  @include mat.badge-theme($flex-app-theme-dark);
  @include mat.button-toggle-theme($flex-app-theme-dark);
  @include mat.divider-theme($flex-app-theme-dark);
  @include mat.snack-bar-theme($flex-app-theme-dark);
}
