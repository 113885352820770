@use '../../../core/styles/media';
@use '../../../core/styles/theme';
@use '../../../material/font';
@use 'sass:map';

.ph-g-card-body-main {
  $content-padding-x: 16px;
  $content-padding-y: 16px;

  min-width: 500px;

  padding: $content-padding-y $content-padding-x;

  &.ph-g-card-body-main-responsive {
    @include media.max-width(m) {
      min-width: auto;
    }
  }

  &.ph-g-card-body-main-l {
    width: 1250px;
  }

  max-width: 1250px;

  flex: 1 1 100%;
  margin: 12px 16px;

  @include media.min-width(m) {
    margin-left: 24px;
    margin-right: 24px;
  }

  &:first-child {
    // No margin top on first card
    margin-top: 0;
  }

  &.with-tabs {
    // + 40px margin

    margin-top: 56px;

    &:first-child {
      margin-top: 40px;
    }
  }

  &.ph-g-card-body-table {
    // Table card https://www.figma.com/file/hXdcbKci3K3zvsI27cIIek/FlexApp?node-id=613%3A2218

    .mat-mdc-card-content > table,
    .mat-mdc-card-content > .mat-mdc-table {
      width: calc(100% + #{2 * $content-padding-x});

      // Mat content escape for table
      margin-left: -#{$content-padding-x};
      margin-right: -#{$content-padding-x};

      td:first-child,
      .mdc-data-table__cell:first-child,
      th:first-child,
      .mdc-data-table__header-cell:first-child {
        // padding on first child to compensate for left margin
        padding-left: $content-padding-x;
      }

      td:last-child,
      .mdc-data-table__cell:last-child,
      th:last-child,
      .mdc-data-table__header-cell:last-child {
        // padding on last child to compensate for right margin
        padding-right: $content-padding-x;
      }
    }

    // Use when a card does not have a paginator
    &.ph-g-card-body-table-clean {
      padding-bottom: 40px;
    }

    // Use when card has a paginator
    &.ph-g-card-body-table-paginator {
      padding-bottom: 0;

      .mat-paginator {
        margin-left: -#{$content-padding-x};
        margin-right: -#{$content-padding-x};

        padding-right: #{map.get(theme.$ph, space, medium)}px;
      }
    }
  }
}

.ph-g-card-body-table table.ph-g-table,
.ph-g-card-body-table .mat-mdc-table.ph-g-table {
  tr,
  .mat-mdc-row {
    height: 48px;
  }

  td,
  th,
  .mdc-data-table__cell,
  .mdc-data-table__header-cell {
    border-color: map.get(theme.$ph, color, ui, support, grey-light);
    @include font.sizePx(xsmall);
  }

  th,
  .mdc-data-table__header-cell {
    color: map.get(theme.$ph, color, ui, support, grey-dark);
  }

  tr.ph-flex-no-data-row,
  .mat-mdc-row .ph-flex-no-data-row {
    min-height: #{ph-flex-no-data}px;
    text-align: center;
    font-style: italic;
  }
}
