/* For use in src/lib/core/theming/_palette.scss */
@use '../src/lib/material/styles/palette';

$md-primary: palette.$palette-flex-primary;

$md-secondary: palette.$palette-flex-secondary;

$md-phgold: (
  50: #f6f1e0,
  100: #e8ddb3,
  200: #d8c680,
  300: #c8af4d,
  400: #bd9d26,
  500: #b18c00,
  600: #aa8400,
  700: #a17900,
  800: #986f00,
  900: #885c00,
  A100: #ffe3b4,
  A200: #ffd081,
  A400: #ffbc4e,
  A700: #ffb335,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #09273f,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);

$md-energylight: (
  50: #fbf6e7,
  100: #f5e9c2,
  200: #efda99,
  300: #e8cb70,
  400: #e3c052,
  500: #deb533,
  600: #daae2e,
  700: #d5a527,
  800: #d19d20,
  900: #c88d14,
  A100: #fffdf9,
  A200: #ffebc6,
  A400: #ffd993,
  A700: #ffd07a,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #09273f,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);

$md-energydark: (
  50: #f3f0e6,
  100: #e2d9c1,
  200: #cebf98,
  300: #baa56f,
  400: #ac9250,
  500: #9d7f31,
  600: #95772c,
  700: #8b6c25,
  800: #81621f,
  900: #6f4f13,
  A100: #ffdda6,
  A200: #ffca73,
  A400: #ffb640,
  A700: #ffad26,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #09273f,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);

$md-alert: palette.$palette-flex-warn;

$md-success: (
  50: #ecf8e7,
  100: #ceedc3,
  200: #aee19b,
  300: #8ed473,
  400: #75cb55,
  500: #5dc237,
  600: #55bc31,
  700: #4bb42a,
  800: #41ac23,
  900: #309f16,
  A100: #daffd3,
  A200: #afffa0,
  A400: #84ff6d,
  A700: #6fff53,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #09273f,
    500: #09273f,
    600: #09273f,
    700: #09273f,
    800: #ffffff,
    900: #ffffff,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);

$md-warn: (
  50: #fdf4e3,
  100: #fae3b8,
  200: #f6d189,
  300: #f2be59,
  400: #f0b036,
  500: #eda212,
  600: #eb9a10,
  700: #e8900d,
  800: #e5860a,
  900: #e07505,
  A100: #ffffff,
  A200: #ffe9d5,
  A400: #ffcda2,
  A700: #ffbf89,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #09273f,
    500: #09273f,
    600: #09273f,
    700: #09273f,
    800: #09273f,
    900: #09273f,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);

$md-text: (
  50: #e1e5e8,
  100: #b5bec5,
  200: #84939f,
  300: #536879,
  400: #2e475c,
  500: #09273f,
  600: #082339,
  700: #061d31,
  800: #051729,
  900: #020e1b,
  A100: #5991ff,
  A200: #266fff,
  A400: #0051f2,
  A700: #0048d9,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #09273f,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff
  )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-mediumgrey: (
  50: #ebedef,
  100: #ccd2d7,
  200: #aab5bd,
  300: #8897a3,
  400: #6f808f,
  500: #556a7b,
  600: #4e6273,
  700: #445768,
  800: #3b4d5e,
  900: #2a3c4b,
  A100: #96ccff,
  A200: #63b4ff,
  A400: #309bff,
  A700: #178fff,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #ffffff
  )
);

$md-lightgrey: (
  50: #f4f6f7,
  100: #e3e7ea,
  200: #d1d8dd,
  300: #bfc8cf,
  400: #b1bcc4,
  500: #a3b0ba,
  600: #9ba9b3,
  700: #91a0ab,
  800: #8897a3,
  900: #778794,
  A100: #ffffff,
  A200: #e6f3fe,
  A400: #b2dcff,
  A700: #98d1ff,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #09273f,
    500: #09273f,
    600: #09273f,
    700: #09273f,
    800: #09273f,
    900: #09273f,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);

$md-brightgrey: (
  50: #fdfdfe,
  100: #fafbfc,
  200: #f6f8fa,
  300: #f2f5f8,
  400: #f0f3f7,
  500: #edf1f5,
  600: #ebeff4,
  700: #e8edf2,
  800: #e5ebf0,
  900: #e0e7ee,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #09273f,
    500: #09273f,
    600: #09273f,
    700: #09273f,
    800: #09273f,
    900: #09273f,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-digitallightblue: (
  50: #e6f5fa,
  100: #c0e7f4,
  200: #96d7ec,
  300: #6bc7e4,
  400: #4cbbdf,
  500: #2cafd9,
  600: #27a8d5,
  700: #219fcf,
  800: #1b96ca,
  900: #1086c0,
  A100: #eff9ff,
  A200: #bce7ff,
  A400: #89d4ff,
  A700: #6fcbff,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #09273f,
    500: #09273f,
    600: #09273f,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);

$md-digitaldarkblue: (
  50: #e2eef7,
  100: #b7d5ec,
  200: #87b9df,
  300: #579cd2,
  400: #3387c9,
  500: #0f72bf,
  600: #0d6ab9,
  700: #0b5fb1,
  800: #0855a9,
  900: #04429b,
  A100: #c6daff,
  A200: #93b9ff,
  A400: #6098ff,
  A700: #4787ff,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-finance: (
  50: #f5ebf3,
  100: #e5cce0,
  200: #d4aacc,
  300: #c288b7,
  400: #b56fa7,
  500: #a85598,
  600: #a04e90,
  700: #974485,
  800: #8d3b7b,
  900: #7d2a6a,
  A100: #ffc2f0,
  A200: #ff8fe4,
  A400: #ff5cd7,
  A700: #ff42d1,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #09273f,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);

$md-financelight: (
  50: #f6eaf7,
  100: #eacaec,
  200: #dca7df,
  300: #cd83d2,
  400: #c369c8,
  500: #b84ebe,
  600: #b147b8,
  700: #a83daf,
  800: #a035a7,
  900: #912599,
  A100: #fcd8ff,
  A200: #f8a5ff,
  A400: #f472ff,
  A700: #f258ff,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #09273f,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);

$md-financedark: (
  50: #edeaf6,
  100: #d3cae9,
  200: #b6a7da,
  300: #9883cb,
  400: #8269c0,
  500: #6c4eb5,
  600: #6447ae,
  700: #593da5,
  800: #4f359d,
  900: #3d258d,
  A100: #d7cdff,
  A200: #af9aff,
  A400: #8767ff,
  A700: #734dff,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #ffffff
  )
);

$md-renewable: (
  50: #e5f5ef,
  100: #bfe6d7,
  200: #94d6bd,
  300: #69c6a2,
  400: #49b98e,
  500: #29ad7a,
  600: #24a672,
  700: #1f9c67,
  800: #19935d,
  900: #0f834a,
  A100: #b5ffd8,
  A200: #82ffbd,
  A400: #4fffa1,
  A700: #36ff94,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #09273f,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);

$md-renewablelight: (
  50: #eef7e9,
  100: #d4ecc9,
  200: #b8dfa5,
  300: #9cd281,
  400: #86c966,
  500: #71bf4b,
  600: #69b944,
  700: #5eb13b,
  800: #54a933,
  900: #429b23,
  A100: #e2ffd9,
  A200: #baffa6,
  A400: #93ff73,
  A700: #80ff59,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #09273f,
    500: #09273f,
    600: #09273f,
    700: #09273f,
    800: #09273f,
    900: #ffffff,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);

$md-renewabledark: (
  50: #e6f7f0,
  100: #c2eadb,
  200: #99dcc3,
  300: #70ceaa,
  400: #51c498,
  500: #32b986,
  600: #2db27e,
  700: #26aa73,
  800: #1fa269,
  900: #139356,
  A100: #c6ffe2,
  A200: #93ffc8,
  A400: #60ffae,
  A700: #47ffa1,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #09273f,
    500: #09273f,
    600: #09273f,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);

$md-on: (
  50: #ecfaf5,
  100: #cff3e5,
  200: #afecd4,
  300: #8fe4c2,
  400: #77deb5,
  500: #5fd8a8,
  600: #57d4a0,
  700: #4dce97,
  800: #43c88d,
  900: #32bf7d,
  A100: #ffffff,
  A200: #d2ffe9,
  A400: #9fffd0,
  A700: #85ffc3,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #09273f,
    500: #09273f,
    600: #09273f,
    700: #09273f,
    800: #09273f,
    900: #09273f,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);

$md-autoon: (
  50: #e6f3ee,
  100: #c2e2d5,
  200: #99cfba,
  300: #70bc9e,
  400: #51ad89,
  500: #329f74,
  600: #2d976c,
  700: #268d61,
  800: #1f8357,
  900: #137244,
  A100: #a8ffd2,
  A200: #75ffb7,
  A400: #42ff9c,
  A700: #29ff8f,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #09273f,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);

$md-off: (
  50: #fdf1ed,
  100: #fbdcd3,
  200: #f9c4b6,
  300: #f6ac99,
  400: #f49b83,
  500: #f2896d,
  600: #f08165,
  700: #ee765a,
  800: #ec6c50,
  900: #e8593e,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffd7d0,
  A700: #ffc1b7,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #09273f,
    500: #09273f,
    600: #09273f,
    700: #09273f,
    800: #09273f,
    900: #09273f,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);

$md-imbalance: (
  50: #e9f9f4,
  100: #c7efe4,
  200: #a2e4d3,
  300: #7dd9c1,
  400: #61d1b3,
  500: #45c9a6,
  600: #3ec39e,
  700: #36bc95,
  800: #2eb58b,
  900: #1fa97b,
  A100: #e2fff5,
  A200: #afffe2,
  A400: #7cffd0,
  A700: #63ffc7,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #09273f,
    500: #09273f,
    600: #09273f,
    700: #09273f,
    800: #09273f,
    900: #09273f,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);

$md-incidentreserve: (
  50: #fbf7eb,
  100: #f6ecce,
  200: #f0dfad,
  300: #ead28c,
  400: #e6c874,
  500: #e1be5b,
  600: #ddb853,
  700: #d9af49,
  800: #d5a740,
  900: #cd992f,
  A100: #ffffff,
  A200: #fff3dd,
  A400: #ffe2aa,
  A700: #ffd990,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #09273f,
    500: #09273f,
    600: #09273f,
    700: #09273f,
    800: #09273f,
    900: #09273f,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);

$md-intraday: (
  50: #f2f0fc,
  100: #ded9f8,
  200: #c9c0f3,
  300: #b3a6ee,
  400: #a293ea,
  500: #9280e6,
  600: #8a78e3,
  700: #7f6ddf,
  800: #7563db,
  900: #6350d5,
  A100: #ffffff,
  A200: #ffffff,
  A400: #d3cdff,
  A700: #bdb3ff,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #09273f,
    500: #09273f,
    600: #09273f,
    700: #09273f,
    800: #09273f,
    900: #ffffff,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);

$md-dayahead: (
  50: #eaf5fd,
  100: #cbe6fa,
  200: #a9d6f7,
  300: #87c5f4,
  400: #6db8f1,
  500: #53acef,
  600: #4ca5ed,
  700: #429beb,
  800: #3992e8,
  900: #2982e4,
  A100: #ffffff,
  A200: #eff6ff,
  A400: #bcdaff,
  A700: #a2ccff,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #09273f,
    500: #09273f,
    600: #09273f,
    700: #09273f,
    800: #09273f,
    900: #09273f,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);

$md-intraday: (
  50: #fcfbef,
  100: #f8f5d7,
  200: #f4eebc,
  300: #f0e7a1,
  400: #ece18c,
  500: #e9dc78,
  600: #e6d870,
  700: #e3d365,
  800: #dfce5b,
  900: #d9c548,
  A100: #ffffff,
  A200: #fffffd,
  A400: #fff7ca,
  A700: #fff3b1,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #09273f,
    500: #09273f,
    600: #09273f,
    700: #09273f,
    800: #09273f,
    900: #09273f,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);

$md-status-play: (
  50: #ebf4f1,
  100: #cee3dd,
  200: #aed1c7,
  300: #8dbeb0,
  400: #74b09f,
  500: #5ca28e,
  600: #549a86,
  700: #4a907b,
  800: #418671,
  900: #30755f,
  A100: #bfffea,
  A200: #8cffd9,
  A400: #59ffc8,
  A700: #40ffbf,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #09273f,
    500: #09273f,
    600: #09273f,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);

$md-status-pause: (
  50: #f9f6ec,
  100: #f1e7d0,
  200: #e8d8b1,
  300: #dfc892,
  400: #d8bc7a,
  500: #d1b063,
  600: #cca95b,
  700: #c6a051,
  800: #c09747,
  900: #b58735,
  A100: #fffffe,
  A200: #ffeccb,
  A400: #ffd898,
  A700: #ffcf7f,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #09273f,
    500: #09273f,
    600: #09273f,
    700: #09273f,
    800: #09273f,
    900: #09273f,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);

$md-status-stop: (
  50: #f8efee,
  100: #eed8d4,
  200: #e3beb7,
  300: #d7a49a,
  400: #cf9185,
  500: #c67d6f,
  600: #c07567,
  700: #b96a5c,
  800: #b16052,
  900: #a44d40,
  A100: #fff8f7,
  A200: #ffccc4,
  A400: #ff9f91,
  A700: #ff8978,
  contrast: (
    50: #09273f,
    100: #09273f,
    200: #09273f,
    300: #09273f,
    400: #09273f,
    500: #09273f,
    600: #09273f,
    700: #09273f,
    800: #ffffff,
    900: #ffffff,
    A100: #09273f,
    A200: #09273f,
    A400: #09273f,
    A700: #09273f
  )
);
