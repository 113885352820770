@use '../core/styles/theme';
@use '../core/styles/media';
@use '../core/styles/unit';
@use 'sass:map';
@use 'sass:list';

// Inspired by https://medium.com/eightshapes-llc/space-in-design-systems-188bcbae0d62

@mixin inset($size: medium, $important: false) {
  $suffix: '';

  @if ($important) {
    $suffix: ' !important';
  }

  padding: #{map.get(theme.$ph, space, $size)}px#{$suffix};
}

/**
 * Inset, but only in the horizontal/x direction.
 */
@mixin inset-x($size: medium, $important: false) {
  $suffix: '';

  @if ($important) {
    $suffix: ' !important';
  }

  padding: 0 #{map.get(theme.$ph, space, $size)}px#{$suffix};
}

@mixin inset-squish($size: medium, $important: false) {
  $spaceMap: map.get(theme.$ph, space);
  $xPadding: map.get($spaceMap, $size);
  $yPadding: getPreviousValueInMap($spaceMap, $size);
  $suffix: '';

  @if ($important) {
    $suffix: ' !important';
  }

  padding: #{$xPadding}px #{$yPadding}px#{$suffix};
}

@mixin inset-stretch($size: medium, $important: false) {
  $spaceMap: map.get(theme.$ph, space);
  $xPadding: map.get($spaceMap, $size);
  $yPadding: getNextValueInMap($spaceMap, $size);
  $suffix: '';

  @if ($important) {
    $suffix: ' !important';
  }

  padding: #{$xPadding}px #{$yPadding}px#{$suffix};
}

@mixin stack($size: medium) {
  display: flex;
  flex-direction: column;

  > *:not(:first-child) {
    margin-top: #{map.get(theme.$ph, space, $size)}px;
  }
}

@mixin inline-container($size: medium) {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  margin-left: -#{map.get(theme.$ph, space, $size)}px;
}

@mixin inline($size: medium) {
  margin-left: #{map.get(theme.$ph, space, $size)}px;
}

@mixin inline-stretch($size: medium) {
  margin-left: #{map.get(theme.$ph, space, $size)}px;
  margin-right: #{map.get(theme.$ph, space, $size)}px;
}

@function getNextValueInMap($map, $currentValue) {
  $keys: map.keys($map);
  $maxIndex: list.length($keys);
  $foundIndex: list.index($keys, $currentValue);

  @if ($foundIndex == null) {
    @error "Item not found";
  }

  // Return current value by default if next value does not exist
  @if ($maxIndex == $foundIndex) {
    // No next value, return current
    @return $currentValue;
  }

  $newKey: list.nth($keys, $foundIndex + 1);
  @return map.get($map, $newKey);
}

@function getPreviousValueInMap($map, $currentValue) {
  $keys: map.keys($map);
  $minIndex: 1;
  $foundIndex: list.index($keys, $currentValue);

  @if ($foundIndex == null) {
    @error "Item not found";
  }

  // Return current value by default if previous value does not exist
  @if ($minIndex == $foundIndex) {
    // No previous value, return current
    @return $currentValue;
  }

  $newKey: list.nth($keys, $foundIndex - 1);
  @return map.get($map, $newKey);
}

@mixin withTopOffsetPx() {
  $topBarHeightM: unit.to-px(map.get(theme.$ph, components, top-bar, min-height, m));
  $filterBarHeightM: unit.to-px(map.get(theme.$ph, components, filter-bar, min-height, m));

  $topBarHeightXs: unit.to-px(map.get(theme.$ph, components, top-bar, min-height, xs));
  $filterBarHeightXs: unit.to-px(map.get(theme.$ph, components, filter-bar, min-height, xs));

  @content ($topBarHeightM + $filterBarHeightM);

  @include media.max-width(m) {
    @content ($topBarHeightXs + $filterBarHeightXs);
  }
}
