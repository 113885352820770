@use 'flex-app-shared/src/lib/core/styles/media';
@use 'flex-app-shared/styles/mixins';

/* You can add global styles to this file, and also import other style files */
// Globals
@import 'normalize.css';
@import '@angular/cdk/overlay-prebuilt.css';
@import 'flex-app-shared/styles/palette';
@import 'flex-app-shared/styles/mixins';
@import 'flex-app-shared/styles/typography';
@import 'flex-app-shared/styles/theming';
@import 'flex-app-shared/styles/settings';

@include init-typography();

// Components
@import 'flex-app-shared/styles/buttons';
@import 'flex-app-shared/styles/calendars';
@import 'flex-app-shared/styles/cards';
@import 'flex-app-shared/styles/calendars';
@import 'flex-app-shared/styles/chips';
@import 'flex-app-shared/styles/dialogs';
@import 'flex-app-shared/styles/forms';
@import 'flex-app-shared/styles/icons';
@import 'flex-app-shared/styles/layout';
@import 'flex-app-shared/styles/legends';
@import 'flex-app-shared/styles/links';
@import 'flex-app-shared/styles/lists';
@import 'flex-app-shared/styles/menus';
@import 'flex-app-shared/styles/paginator';
@import 'flex-app-shared/styles/sidenav';
@import 'flex-app-shared/styles/states';
@import 'flex-app-shared/styles/stepper';
@import 'flex-app-shared/styles/tables';
@import 'flex-app-shared/styles/tabs';
@import 'flex-app-shared/styles/toolbars';
@import 'flex-app-shared/styles/tooltips-hints';
@import 'flex-app-shared/styles/utils';

@import 'flex-app-shared/src/lib/core/styles/components/containers';
@import 'flex-app-shared/src/lib/core/styles/components/cards';
@import 'flex-app-shared/src/lib/core/styles/components/buttons';
@import 'flex-app-shared/src/lib/core/styles/components/form-field';

.page-content {
  padding: 0 1rem 1rem;
}

@include mixins.formHeight(32px);

@include media.max-width('m') {
  .filter-bar .mat-form-field {
    width: 100%;
    margin-bottom: 1rem;
  }
}

@include media.min-width('m') {
  .filter-bar {
    flex-direction: row;
  }
}

.mat-drawer-container {
  height: 100%;
}

// new styling

@import 'flex-app-shared/src/lib/core/styles/components/buttons';

// Icon styling
.mat-column-actions .mat-icon-button .mat-icon {
  @include mixins.icon-size(20px);
}

.mdc-data-table__header-cell .mat-icon {
  @include mixins.icon-size(14px);
}

.mat-form-field-suffix .mat-icon {
  @include mixins.icon-size(20px);
}

// Menu override
.mat-menu-panel .mat-menu-item {
  height: 32px;
  line-height: 32px;
}

// Card layout fix
// With the new .page-content > :not(router-outlet) styling, the cards no longer have the correct initial width.
// This is fixed for the admin by this global styling, but could also be fixed by using .ph-g-container-page

.page-content > :not(router-outlet) {
  & > .mat-mdc-card,
  & > .mat-mdc-stepper-vertical {
    width: 100%;
    margin-left: 13px;
    margin-right: 13px;
    box-sizing: border-box;
  }
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}

// Material select column should be as small as possible
.mat-column-select {
  width: 39px;
}
