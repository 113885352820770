@use '@angular/material' as mat;
@use 'sass:map';
@use 'sass:color';
@use '../src/lib/core/styles/theme';
@use '../src/lib/core/styles/media';
@use '../src/lib/core/styles/mixins/text-crop';

$input-border-color: map.get(theme.$ph, color, ui, support, grey-dark);
$input-border-color-active: map.get(theme.$ph, color, brand, accent);

// TODO(arno): Temporary fix until this MD issue has been resolved: https://github.com/angular/components/issues/8798
// Checkbox in dialog causes unwanted scrollbar.
.mat-mdc-checkbox-ripple {
  height: $table-row-height !important;
  width: $table-row-height !important;
  left: calc(50% - (#{$table-row-height} / 2)) !important;
  top: calc(50% - (#{$table-row-height} / 2)) !important;
}

.mat-mdc-radio-ripple {
  height: $table-row-height !important;
  width: $table-row-height !important;
  left: calc(50% - (#{$table-row-height} / 2)) !important;
  top: calc(50% - (#{$table-row-height} / 2)) !important;
}

// Remove input number default browser up/down arrows
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

// SELECT
.mat-mdc-select-trigger {
  height: 1.155em !important;
}

// Radio Buttons
.mat-mdc-radio-group {
  .mat-radio-button {
    margin-bottom: 4px;
  }

  &.vertical {
    display: flex;
    flex-direction: column;
    margin: 15px 0;

    &__item {
      margin: 5px;
    }
  }

  .mat-icon {
    width: 14px;
    height: 14px;
  }
}

// Slide Toggle
.mat-mdc-slide-toggle {
  height: 32px !important;
  font-weight: 600;

  &.mat-disabled {
    opacity: 1 !important;
    filter: opacity(0.38);
  }
}

// Checkbox group (horizontal)
.checkbox-group {
  min-height: 32px;
}

.mat-mdc-form-field:not(.ph-flex-mat-form-field-on-dark-bg) {
  .mdc-notched-outline {
    background-color: white;
    color: $input-border-color;
  }

  &.mat-form-field-disabled .mdc-notched-outline {
    background-color: map.get(theme.$ph, color, ui, support, grey-light);
  }

  .mdc-text-field--focussed .mdc-notched-outline {
    color: $input-border-color-active;
  }

  .mat-mdc-form-field-label {
    color: rgba(mat.m2-get-color-from-palette($md-primary, 500), 0.6);
  }
}

.mat-button-toggle-group.ph-flex-mat-form-field-on-dark-bg {
  border-color: map.get(theme.$ph, color, ui, support, grey);
  color: map.get(theme.$ph, color, ui, support, grey);

  .mat-button-toggle {
    border-color: map.get(theme.$ph, color, ui, support, grey);
  }

  .mat-button-toggle-button {
    color: white;
    background: map.get(theme.$ph, color, brand, primary-light);

    &:hover {
      background: map.get(theme.$ph, color, ui, support, grey-dark);
    }
  }

  .mat-button-toggle-checked .mat-button-toggle-button {
    background: map.get(theme.$ph, color, ui, support, grey-dark);
  }
}

.mdc-menu-surface {
  background-color: white;
}

.mat-mdc-form-field {
  .mat-mdc-form-field-infix,
  .mat-mdc-form-field-icon-suffix,
  .mat-mdc-form-field-text-suffix {
    // Required to appear above background of mdc-notched-outline
    z-index: 1;
  }

  .mat-mdc-form-field-icon-suffix .mat-icon {
    padding: 0;
    width: 20px;
    height: 20px;
  }

  .mat-mdc-form-field-subscript-wrapper {
    line-height: 25px;
  }
}

.mat-mdc-form-field.ph-flex-mat-form-field-on-dark-bg {
  color: white; // text color

  .mat-mdc-input-element {
    caret-color: white;
    color: white; // text color
  }

  .mdc-notched-outline {
    background-color: map.get(theme.$ph, color, brand, primary-light);
    color: map.get(theme.$ph, color, ui, support, grey); // outline (border) color
  }

  .mdc-text-field--focused .mdc-notched-outline {
    color: $input-border-color-active;

    mat-label {
      color: map.get(theme.$ph, color, ui, support, grey);
    }
  }

  .mat-mdc-form-field-label {
    color: map.get(theme.$ph, color, ui, support, grey);
  }

  .mat-mdc-icon-button,
  .mat-mdc-select-value,
  .mat-mdc-select-arrow,
  .mat-mdc-date-range-input-separator {
    color: white;
  }

  /*
   * disabled
   */

  &.mat-mdc-form-field-disabled {
    background-color: map.get(theme.$ph, color, brand, primary-dark);

    .mat-mdc-form-field-label {
      color: map.get(theme.$ph, color, ui, text, inactive);

      mat-mdc-label {
        background-color: map.get(theme.$ph, color, brand, primary-dark);
      }
    }

    .mat-mdc-select-value {
      color: map.get(theme.$ph, color, ui, text, inactive);
    }

    .mdc-notched-outline {
      background-color: transparent;

      .mdc-notched-outline-start,
      .mdc-notched-outline-gap,
      .mdc-notched-outline-end {
        border-color: map.get(theme.$ph, color, ui, text, inactive);
      }
    }
  }

  .mat-mdc-input-element:disabled {
    color: map.get(theme.$ph, color, ui, text, inactive);
  }
}

.ph-flex-mat-form-field-slim {
  width: 15ch;
}

.ph-flex-mat-form-field-regular {
  width: 19ch;
}

.ph-flex-mat-form-field-medium {
  width: 25ch;
}

.ph-flex-mat-form-field-large {
  width: 32ch;
}

.ph-flex-mat-form-field-wide {
  width: 43ch;
}

.ph-flex-mat-form-field-group-inline {
  @include media.min-width('l') {
    .mat-mdc-form-field {
      &:not(:last-child) {
        margin-right: 1rem;
      }

      .mat-form-field-wrapper {
        margin: 0;
        padding-bottom: inherit;
      }
    }
  }
}

.mat-mdc-dialog-container {
  // on < large screens, input fields will stack vertically
  @include media.max-width('l') {
    .mat-mdc-form-field {
      width: 100%;
    }
  }
}

// MD OUTLINE STYLE FORMS
.mat-mdc-form-field-appearance-outline {
  // START COLOR STYLING
  // Only the color styling attributes

  &.mat-focused {
    .mat-form-field-outline {
      box-shadow: 3px 3px 8px 0px rgba(mat.m2-get-color-from-palette($md-lightgrey, 400), 0.4);
    }

    .mdc-notched-outline-start,
    .mdc-notched-outline-gap,
    .mdc-notched-outline-end {
      color: $input-border-color-active;
    }
  }

  &.mat-mdc-form-field[readonly] {
    .mdc-notched-outline {
      background: rgba(mat.m2-get-color-from-palette($md-mediumgrey, 50), 0.3);
    }
  }

  &.mat-mdc-form-field-disabled {
    .mdc-notched-outline {
      background: rgba(mat.m2-get-color-from-palette($md-mediumgrey, 50), 0.5);
    }
  }

  .mat-mdc-form-field-suffix {
    color: rgba(mat.m2-get-color-from-palette($md-primary, 500), 0.6);
  }

  // END COLOR STYLING

  // START LAYOUT STYLING

  input.mat-mdc-input-element {
    font-size: 14px;
    height: 100%; // centers text regardless of specific font usage
    margin-top: -0.1875rem; // 3px to accommodate custom font size of 14px (stated above on this class)
  }

  .mat-form-field-outline {
    top: 0 !important;
    border-radius: $border-radius;
  }

  .mat-form-field-wrapper {
    margin: 0;
    padding-bottom: 24px;
  }

  .mat-mdc-form-field-flex {
    margin-top: 0 !important;
  }

  // Label + Floating label
  .mat-form-field-label-wrapper {
    padding-bottom: 1.1em !important;

    .mat-form-field-label {
      margin-top: 0 !important;
      padding-bottom: 8px;
    }
  }

  &.mat-form-field-can-float {
    &.mat-form-field-should-float .mat-form-field-label {
      //transform: translateY(-13px) scale(0.75) !important;
    }
  }

  // Form field "zones", prefix | infix | suffix
  .mat-form-field-infix {
    border-top-width: 0;
  }

  .mat-form-field-suffix {
    align-self: center;
    top: 0px !important;

    .mat-icon-button {
      margin-top: 2px;
      width: 1.4em !important;
      height: 1.4em !important;
    }
  }

  // Select adjustments
  .mat-select-arrow-wrapper {
    transform: translateY(0) !important;
  }

  // Hint / Alert text positioning
  .mat-form-field-subscript-wrapper {
    margin-top: -4px !important;
  }

  // END LAYOUT STYLING

  // CUSTOM FORM CLASSES
  // Class to remove bottom spacing (for hints/errors) in case they need to be aligned with buttons in a row (horizontally)
  &.no-bottom-space {
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }
  }
}

.mat-mdc-autocomplete-panel,
.mat-mdc-select-panel {
  --mat-select-panel-background-color: white;
  --mat-autocomplete-background-color: white;
  --mat-autocomplete-container-elevation-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  --mat-select-container-elevation-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;

  padding: 0 !important;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;

  .mat-mdc-option-active,
  .mat-mdc-option:hover {
    background: rgba(0, 0, 0, 0.04);
  }
}

.mat-mdc-autocomplete-panel .mat-mdc-option,
.mat-mdc-select-panel .mat-mdc-optgroup-label,
.mat-mdc-select-panel .mat-mdc-option {
  min-height: 2.6em;
  line-height: 2.6em;
  @include mat.m2-typography-level($flex-app-typography, body-1);
}

// Select value text overflow
.mat-mdc-select-panel,
.mat-mdc-autocomplete-panel {
  .mat-mdc-option {
    .mdc-list-item__primary-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 2em;
    }
  }
}

// Grouping of related form elements
.group-related-form-elements {
  border: dotted 2px rgba(mat.m2-get-color-from-palette($md-mediumgrey, 500), 0.4);
  background: rgba(mat.m2-get-color-from-palette($md-mediumgrey, 500), 0.04);
  border-radius: $border-radius;
  padding: $gutter * 0.5 !important;
  padding-bottom: 0;
  margin-bottom: $gutter * 0.5;

  // Alignment checkbox item
  .mat-checkbox {
    margin-left: 6px;
    vertical-align: -moz-middle-with-baseline;
    vertical-align: -webkit-baseline-middle;
  }
}

.checkbox-group {
  margin-bottom: 1.34375em;

  &:not(.list) {
    > .mat-checkbox {
      margin: 0 10px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &.list {
    > .mat-checkbox {
      display: block;
      margin-bottom: 10px;
      margin-top: 6px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

//TODO: Refactor
.select-with-arrows {
  display: flex;

  &.v-align-fix {
    // To fix L/R space icon buttons have, to align with other content.
    margin: 0 -10px;
  }

  .mat-icon-button {
    flex: 0 0 auto;
  }

  .mat-form-field {
    flex: 1 1 100%;
  }
}

// FOR FF FIXES
@-moz-document url-prefix() {
  .mdc-notched-outline {
    .mat-form-field-infix {
      padding: 0.9em 0 1em 0;
    }

    .mat-select-arrow-wrapper {
      transform: translateY(1px) !important;
    }
  }
}

.mat-checkbox.mat-checkbox-on-dark-bg {
  color: white;

  .mat-checkbox-frame {
    background-color: white;
  }
}

.mat-form-field .mat-form-field-prefix .mat-datepicker-toggle .mat-mdc-button-base,
.mat-form-field .mat-form-field-suffix .mat-datepicker-toggle .mat-mdc-button-base {
  --flex-icon-button-icon-size: 20px;
}

.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-27.75px) scale(0.75) !important;
}

.mdc-text-field--outlined {
  --mdc-shape-small: 5px;

  padding-left: max(5px, calc(var(--mdc-shape-small, 4px) + 4px)) !important;
  padding-right: max(10px, var(--mdc-shape-small, 4px)) !important;

  .mdc-notched-outline .mdc-notched-outline__leading {
    // Make left spacing smaller
    width: max(5px, var(--mdc-shape-small, 3px)) !important;
  }

  + .mat-mdc-form-field-subscript-wrapper > * {
    // Apply left spacing to error and hint subscripts
    padding-left: 5px !important;
  }

  .mat-mdc-form-field-has-icon-suffix & {
    padding-right: 2px !important;
  }
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 10px !important;

  .mat-mdc-form-field-error {
    color: map.get(theme.$ph, color, ui, alerts, error);
  }

  .mat-mdc-form-field-hint,
  .mat-mdc-form-field-error {
    font-size: 9px;
    line-height: 11px;
  }

  .mat-mdc-form-field-bottom-align::before {
    height: 11px;
  }

  .mat-mdc-form-field-error + .mat-mdc-form-field-error {
    // Only show first error message
    display: none;
  }
}

.mdc-text-field--invalid {
  .mdc-notched-outline {
    color: map.get(theme.$ph, color, ui, alerts, error) !important;
  }
}

html {
  // Toggle switch
  $track-color-unselected: map.get(theme.$ph, color, ui, support, grey);
  $track-color-selected: color.mix(map.get(theme.$ph, color, brand, accent), white, 70%);

  $track-color-unselected-disabled: color.mix(map.get(theme.$ph, color, ui, support, grey), white, 70%);
  $track-color-selected-disabled: color.mix(map.get(theme.$ph, color, ui, support, grey), white, 80%);

  $handle-color-unselected: map.get(theme.$ph, color, ui, support, grey-dark);
  $handle-color-unselected-hover: map.get(theme.$ph, color, ui, support, grey-dark);

  $handle-color-selected: color.mix(map.get(theme.$ph, color, brand, accent), white, 90%);
  $handle-color-selected-hover: map.get(theme.$ph, color, brand, accent);

  $handle-color-unselected-disabled: color.mix(map.get(theme.$ph, color, ui, support, grey-dark), white, 70%);
  $handle-color-selected-disabled: color.mix(map.get(theme.$ph, color, ui, support, grey-dark), white, 40%);

  --mdc-switch-unselected-track-color: #{$track-color-unselected};
  --mdc-switch-unselected-handle-color: #{$handle-color-unselected};
  --mdc-switch-unselected-icon-color: white;

  --mdc-switch-unselected-focus-track-color: #{$track-color-unselected};
  --mdc-switch-unselected-focus-state-layer-color: #{$handle-color-unselected};
  --mdc-switch-unselected-focus-handle-color: #{$handle-color-unselected-hover};

  --mdc-switch-unselected-hover-track-color: #{$track-color-unselected};
  --mdc-switch-unselected-hover-state-layer-color: #{$handle-color-unselected};
  --mdc-switch-unselected-hover-handle-color: #{$handle-color-unselected-hover};

  --mdc-switch-unselected-pressed-track-color: #{$track-color-unselected};
  --mdc-switch-unselected-pressed-state-layer-color: #{$handle-color-unselected};
  --mdc-switch-unselected-pressed-handle-color: #{$handle-color-unselected-hover};

  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #{$track-color-unselected-disabled};
  --mdc-switch-disabled-unselected-handle-color: #{$handle-color-unselected-disabled};

  --mdc-switch-selected-track-color: #{$track-color-selected};
  --mdc-switch-selected-handle-color: #{$handle-color-selected};
  --mdc-switch-selected-icon-color: white;
  --mat-switch-selected-track-outline-width: 0;
  --mdc-switch-handle-surface-color: #{$handle-color-unselected-disabled};


  --mdc-switch-selected-focus-track-color: #{$track-color-selected};
  --mdc-switch-selected-focus-state-layer-color: #{$handle-color-selected};
  --mdc-switch-selected-focus-handle-color: #{$handle-color-selected};

  --mdc-switch-selected-hover-track-color: #{$track-color-selected};
  --mdc-switch-selected-hover-state-layer-color: #{$handle-color-selected};
  --mdc-switch-selected-hover-handle-color: #{$handle-color-selected-hover};

  --mdc-switch-selected-pressed-state-layer-color: #{$handle-color-selected};
  --mdc-switch-selected-pressed-track-color: #{$track-color-selected};
  --mdc-switch-selected-pressed-handle-color: #{$handle-color-selected-hover};

  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #{$track-color-selected-disabled};
  --mdc-switch-disabled-selected-handle-color: #{$handle-color-selected-disabled};

  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-track-outline-width: 0;




  // Radio button
  --flex-radio-state-layer-size: 26px;
  --mdc-radio-selected-icon-color: #{map.get(theme.$ph, color, brand, accent)};
  --mdc-radio-selected-hover-icon-color: #{map.get(theme.$ph, color, brand, accent)};
  --mdc-radio-selected-pressed-icon-color: #{map.get(theme.$ph, color, brand, accent)};

  .mat-mdc-radio-button {
    --mdc-radio-state-layer-size: var(--flex-radio-state-layer-size, 40px);
  }

  // Checkbox
  --mdc-checkbox-unselected-hover-icon-color: #{map.get(theme.$ph, color, brand, accent)};
  --mdc-checkbox-selected-icon-color: #{map.get(theme.$ph, color, brand, accent)};
  --mdc-checkbox-selected-hover-icon-color: #{map.get(theme.$ph, color, brand, accent)};
  --mdc-checkbox-selected-pressed-icon-color: #{map.get(theme.$ph, color, brand, accent)};
  --mdc-checkbox-selected-focus-icon-color: #{map.get(theme.$ph, color, brand, accent)};
  --mdc-checkbox-selected-checkmark-color: white;

  --mdc-checkbox-disabled-selected-icon-color: #{map.get(theme.$ph, color, ui, support, grey)};
}

.mat-mdc-form-field-type-mat-select .mat-mdc-form-field-text-suffix {
  padding-left: 6px;

  & + .mat-mdc-form-field-text-suffix {
    padding-left: 0;
  }
}

.mat-mdc-text-field-wrapper {
  // Prevent multi line inputs with wrapping issues
  flex: 0 0 auto;
}
