.mat-icon {
  // FIX for icons not rendering in Chrome without a trailing space or other char.
  letter-spacing: 0.001em;

  // Apparently letter-spacing alone wasn't enough. Maybe just not use ligatures with non-alphabetical characters?
  // See https://stackoverflow.com/questions/31717920/ligatures-in-chrome-only-displaying-when-adding-other-characters
  white-space: pre;

  // Prevent the title element from causing a tooltip
  svg {
    pointer-events: none;
  }
}
// END FIX

// ICON SIZES
@include setIconSizes;

mat-toolbar > mat-icon {
  padding-right: 8px;
}

//.mat-mini-fab mat-icon {
//  vertical-align: text-bottom !important;
//}

// SPINNING ICONS
@keyframes anim-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.icon-spin-anim {
  animation: anim-rotate 2s infinite linear;

  &--steps8 {
    animation: anim-rotate 1s infinite steps(8);
  }

  &--steps10 {
    animation: anim-rotate 1s infinite steps(12);
  }
}
