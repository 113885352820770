@use '../../../core/styles/media';
@use '../../../core/styles/theme';
@use 'sass:map';

.ph-g-container-page {
  display: inline-flex;
  justify-content: center;
  flex-wrap: wrap;

  margin-top: 24px;

  @include media.max-width(m) {
    margin-bottom: #{map.get(theme.$ph, space, medium)}px;
  }

  > .mat-mdc-card {
    width: 1250px;
    max-width: min(100vw, 1250px);
    box-sizing: border-box;
  }
}

html {
  --mat-form-field-container-height: 20px;
}

.mat-mdc-form-field {
  //--mat-mdc-form-field-floating-label-scale: 0.5 !important;
  --mat-form-field-container-height: 28px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}
