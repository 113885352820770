@use '@angular/material' as mat;
.spacer {
  flex: 1 1 auto; // todo: misschien mat-divider gebruiken?
}

.hide {
  display: none !important;
}

.clickable {
  cursor: pointer !important;
}

.inline {
  display: inline !important;
}

// ALIGNMENT CLASSES
.aCenter {
  text-align: center;
}

.aRight {
  text-align: right;
}

.no-margin {
  margin: 0;
}

.nowrap {
  white-space: nowrap;
}

strong {
  font-weight: 600;
}

.sticky {
  position: fixed;
  top: 0;
  overflow: hidden;
  z-index: 10;
}

// DIVIDER CLASSES
.mat-divider {
  text-align: center;

  &.mat-divider-horizontal {
    position: relative !important;
  }

  &.spacer {
    &__small {
      margin: $gutter * 0.5 0 $gutter * 0.5 0;
    }

    &__medium {
      margin: $gutter 0 $gutter 0;
    }

    &__large {
      margin: 2 * $gutter 0 2 * $gutter 0;
    }

    &__huge {
      margin: 3 * $gutter 0 3 * $gutter 0;
    }

    &__bottom {
      margin-bottom: $gutter;
    }
  }

  &.noline {
    border: none !important;
  }
}

.icon-seperator {
  margin-bottom: $gutter * 0.5;
  opacity: 0.8;
  font-size: 24px;
}

.fill-remaining-space {
  flex: 1 1 auto;
}

.weight-medium {
  font-weight: 400;
}

// ROTATE CLASSES
.rotate-45 {
  @include rotate(45deg);
}

.rotate-90 {
  @include rotate(90deg);
}

.rotate-180 {
  @include rotate(180deg);
}

.rotate-neg-45 {
  @include rotate(-45deg);
}

.rotate-neg-90 {
  @include rotate(-90deg);
}

.set-default {
  color: mat.m2-get-color-from-palette($md-digitaldarkblue, 500) !important;
}

.preview-msg {
  border: dashed 1px mat.m2-get-color-from-palette($md-lightgrey, 500);
  background-color: mat.m2-get-color-from-palette($md-brightgrey, 500);
  padding: 8px 12px !important;
  margin: 0 !important;
  border-radius: $border-radius;
}
