mat-list[dense] {
  //mat-icon {
  // TODO replace?
  //  font-size: 24px;
  //}

  mat-list-item {
    font-size: 16px;
  }

  &.sidenav {
    // So the versioning text doesn't overlap the menu items:
    padding-bottom: 70px;
  }
}
