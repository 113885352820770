@use '@angular/material' as mat;
@use 'sass:map';
@use '../../flex-app-shared/src/lib/core/styles/theme';

mat-sidenav {
  padding: 0 6px 0 2px;

  .mat-mdc-list-base[dense] {
    .mat-mdc-list-item {
      height: 32px;
      padding: 0;

      &.logo {
        padding-top: 6px;
        padding-left: 6px;
        box-sizing: border-box;

        img {
          height: 38px;
        }
      }

      .mdc-list-item__content {
        padding: 0 6px;
        display: flex;

        .mat-mdc-list-item-unscoped-content {
          flex: 1 1 auto;
        }

        button {
          padding: 0 14px;
          flex: 1 1 auto;
          text-align: left;
          text-transform: capitalize;
          font-size: 14px;
          font-weight: 600;
          padding-bottom: 2px;

          app-page-title {
            color: white;
            white-space: nowrap;

            .mat-icon {
              margin-right: 5px;
            }
          }

          &:not(.mat-accent) {
            &:hover {
              background-color: rgba(mat.m2-get-color-from-palette($md-digitaldarkblue, 500), 0.35);
            }
          }

          &.selected {
            background-color: mat.m2-get-color-from-palette($md-digitaldarkblue, 700) !important;
          }
        }
      }
    }
  }
  ph-flex-sidenav-menu-item {
    outline: 0;
  }
}

mat-sidenav.mat-drawer {
  background: mat.m2-get-color-from-palette($md-primary, 500);
}
