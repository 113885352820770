@use '@angular/material' as mat;
@use 'sass:map';
@use '../src/lib/core/styles/theme';
@use '../styles/mixins';

.mat-mdc-menu-panel {
  min-height: 32px !important;
  min-width: 128px !important;
  --mat-menu-container-color: white;

  .mat-mdc-menu-item {
    --mat-menu-item-leading-spacing: 18px;
    --mat-menu-item-with-icon-leading-spacing: 18px;
    --mat-menu-item-trailing-spacing: 18px;
    --mat-menu-item-with-icon-trailing-spacing: 18px;

    &:hover {
      --mat-menu-item-hover-state-layer-color: #{rgba(mat.m2-get-color-from-palette($md-digitallightblue, 500), 0.08)};
    }
    .mat-icon {
      margin-right: $button-spacing;
      @include mixins.icon-size(20px);
    }
    img {
      width: 19px;
      vertical-align: middle;
      margin-top: -2px;
      margin-right: 6px;
    }

    .mdc-list-item__primary-text {
      line-height: 2em; // Increase line height to avoid issues with overflow: hidden
    }
  }
}

.top-bar-menu {
  @include mat.elevation(1);
  --mat-toolbar-container-background-color: #{mat.m2-get-color-from-palette($md-primary, 500)};
  padding: 0 6px !important;
  height: 40px !important;

  .mat-mdc-button-base {
    &[color='primary'] {
      &:hover {
        background-color: rgba(mat.m2-get-color-from-palette($md-digitaldarkblue, 500), 0.35);
      }
    }
    &[color='accent'] {
      &:hover {
        background-color: rgba(mat.m2-get-color-from-palette($md-secondary, 500), 0.8);
      }
    }
  }
  .mat-mdc-button {
    background: white;
    margin-right: 1px;
  }
  .mat-divider-vertical {
    &.for-navs {
      display: inline;
      border-right-color: rgba(white, 0.2);
      margin: 0 4px;
      height: 40px;
    }
  }

  .spacer {
    flex: 1 1 auto;
  }
}
