@import 'typography';

// PATHS
$imagePath: '~/assets/images';

// LAYOUT
$gutter: 16px;

// STYLE
$border-radius: 5px;

// TABLES
$table-row-height: 26px;
$table-row-height-condensed: 20px;

// BUTTONS
$button-spacing: 10px;
$button-icon-size: 20px;

// ICONS
$ic-size-xs: 10px;
$ic-size-s: 16px;
$ic-size-m: 20px;
$ic-size-l: 24px;
$ic-size-xl: 28px;
$ic-size-xxl: 32px;
$ic-size-xxxl: 100px;
