@use 'sass:map';
@use '../src/lib/core/styles/theme';

@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/css?family=Sarabun:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Quicksand:700,display=swap');

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.

// Smoother font rendering for MAC OS.
// TODO: for windows we may do this trick: https://medium.com/better-programming/improving-font-rendering-with-css-3383fc358cbc
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Set body text size
$body-text: 13px;

$flex-app-typography: mat.m2-define-typography-config(
  $font-family: '#{map.get(theme.$ph, fontFamilies, running)}, Sarabun, "Helvetica Neue", sans-serif',
  $headline-1: mat.m2-define-typography-level($body-text + 12px, $body-text + 16px, 600, map.get(theme.$ph, fontFamilies, headings)),
  $subtitle-2: mat.m2-define-typography-level($body-text + 6px, $body-text + 10px, 600, map.get(theme.$ph, fontFamilies, headings)),
  $subtitle-1: mat.m2-define-typography-level($body-text + 3px, $body-text + 12px, 600, map.get(theme.$ph, fontFamilies, headings)),
  $body-2: mat.m2-define-typography-level($body-text + 1px, $body-text + 6px, 500),
  $body-1: mat.m2-define-typography-level($body-text, $body-text + 5px, 400),
  $caption: mat.m2-define-typography-level($body-text - 1px, $body-text + 8px, 400),
  $button: mat.m2-define-typography-level($body-text, $body-text, 500)
);

@mixin init-typography() {
  html {
    font-family: #{map.get(theme.$ph, fontFamilies, running)}, Sarabun, 'Helvetica Neue', sans-serif;
    --mat-table-header-headline-font: #{map.get(theme.$ph, fontFamilies, running)}, Sarabun, 'Helvetica Neue', sans-serif;
  }

  h3 {
    margin-top: 0;
    margin-bottom: $gutter * 0.5;
    font-weight: 600;
  }

  .mat-tooltip {
    padding-bottom: 8px;
  }

  .mat-step-icon-state-number {
    font-size: 13px;
  }
}
