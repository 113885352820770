@use '@angular/material' as mat;
@use 'sass:map';
@use '../src/lib/core/styles/theme';

.mat-mdc-button-base {
  &.mdc-button--outlined.mat-unthemed {
    // Provide white background for off-colored backgrounds, otherwise it is transparent
    background: white;
  }

  .mat-icon {
    display: flex;
    width: 17px;
    height: 17px;
  }

  text-transform: uppercase;
}

.mdc-icon-button {
  padding: 0 !important; // No padding on icon buttons, is auto positioned

  .mat-icon {
    width: auto;
    height: auto;
  }
}

button.mat-mdc-button-base,
.mat-button-toggle {
  height: var(--flex-button-toggle-height, 32px);

  &.mdc-fab--mini {
    // Also set width for mini fab
    width: var(--flex-button-toggle-height, 32px);
  }

  .mat-button-toggle-label-content,
  & {
    line-height: var(--flex-button-toggle-height, 32px);
  }
}

.mat-datepicker-toggle {
  .mdc-icon-button {
    width: 30px;
    --flex-icon-button-icon-size: 20px;
  }
}

.mat-mdc-fab {
  --flex-button-toggle-height: 56px;
}

.mat-mdc-outlined-button .spinner-button .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #{map.get(theme.$ph, color, brand, primary)};

  &:not(:last-child) {
    // Extra margin if not the last element in the button
    margin-right: 8px;
  }
}

