@use 'sass:map';
@use 'theme';

/**
 * Calculate the value for max-width media queries given a size.
 * Treat max width as <.
 */
@function max-width-calc($size) {
  @if (map.has-key(theme.$ph, layout, width, $size)) {
    @return #{map.get(theme.$ph, layout, width, $size) - 1}px;
  }
  @return 100vw;
}

/**
 * Calculate the value for min-width media queries given a size.
 * Treat min width as >=.
 */
@function min-width-calc($size) {
  @if (map.has-key(theme.$ph, layout, width, $size)) {
    @return #{map.get(theme.$ph, layout, width, $size)}px;
  }
  @return 0;
}

/**
 * Use instead of @media (max-width: px) { ... }
 */
@mixin max-width($size) {
  @media (max-width: #{max-width-calc($size)}) {
    @content;
  }
}

/**
 * Use instead of @media (min-width: px) { ... }
 */
@mixin min-width($size) {
  @media (min-width: min-width-calc($size)) {
    @content;
  }
}

/**
 * $value should be an object with keys matching the layout.width list
 */
@mixin with-x-break-points($value) {
  $breakPoints: map.keys(map.get(theme.$ph, layout, width));
  $isFirst: true;

  @each $breakPoint in $breakPoints {
    @if (map.has-key($value, $breakPoint)) {
      @if ($isFirst) {
        @content (map.get($value, $breakPoint));
      } @else {
        @include min-width($breakPoint) {
          @content (map.get($value, $breakPoint));
        }
      }
      $isFirst: false;
    }
  }
}
